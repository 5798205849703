import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

class PageLoader extends Component {
  /**
   * Renders a button with a default type 'default'.
   */
  render() {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh'}}>
         <Loader style={{paddingTop: 100}} type="Oval" color="grey" height={40} width={40} />
      </div>
    );
  }
}

export default PageLoader;
