import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, Select, Dialog, toaster } from 'evergreen-ui';
import './styles/okrs.scss';
import ApiClient from '../../ApiClient.js';

// shared components
import ResultsRow from './components/ResultsRow.react';
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';
import { analyticsPage } from '../../utils';

class OkrsEditPage extends Component {
  state = {
    objectives: null,
    deleteDialogIndex: null,
    submitClicked: false,
  };
  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    tooManyObjectives: 'There are too many objectives',
    tooManyResults: 'There are too many results',
    objectiveNames: 'Objective names are not valid',
    resultNames: 'Result names are not valid',
  };
  /**
   * Validation values.
   */
  validations = {
    maxCount: 5,
    minName: 1,
  };
  /**
   * Different types of available statuses for objective results.
   */
  statusMap = {
    none: { label: 'None', value: 0 },
    onTrack: { label: 'On Track', value: 1 },
    inDanger: { label: 'In Danger', value: 2 },
    offTrack: { label: 'Off Track', value: 3 },
  };

  constructor(props) {
    super(props);

    ApiClient.get(`/people/${this.props.person_id}/objectives`)
      .then(objectives => this.setState({ objectives }))
      .catch(err => this.onError(err));
  }

  componentDidMount() {
    analyticsPage("okr_edit");
  }

  /**
   * Submits the user's objectives.
   */
  onSubmit() {
    this.setState({ submitClicked: true });

    if (this.objectiveNamesNotValid()) {
      return toaster.danger(this.errors.objectiveNames)
    }

    if (this.objectiveResultNamesNotValid()) {
      return toaster.danger(this.errors.resultNames)
    }

    ApiClient.post(`/people/${this.props.person_id}/objectives`, this.state.objectives)
      .then(() => this.onSaveComplete())
      .catch(err => this.onError(err));
  }

  /**
   * Validates objective names length.
   *
   * @returns {*}
   */
  objectiveNamesNotValid() {
    return this.state.objectives.find(({ name }) => name.length < this.validations.minName)
  }

  /**
   * Validates objective names length.
   *
   * Checks each objective's each result name.
   * @returns {*}
   */
  objectiveResultNamesNotValid() {
    const { objectives } = this.state;

    return objectives.find(objective => {
      return objective.results.find(({ name }) => name.length < this.validations.minName);
    });
  }

  /**
   * Profile updated event handler.
   *
   * Navigates to the profile page.
   */
  onSaveComplete() {
    this.props.history.push(`/people/${this.props.person_id}`);
  }

  /**
   * Error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected)
  }

  /**
   * Renders the top content.
   */
  renderTopContent() {
    const title = 'Edit Objectives';

    return (
      <PageTopContent
        title={title} >
        <div className="ml-auto mt-auto d-flex flex-row ">
          <Button
            onClick={() => this.onAddObjectiveClicked()}
            appearance="dark"
            className="ml-auto px-3 mr-4"
            >Add Objective</Button>

          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto px-3"
            >Save</Button>
        </div>
      </PageTopContent>
    );
  }

  /**
   * Renders an objective's results.
   *
   * @param name
   * @param status
   * @param objectiveIndex
   * @param resultIndex
   * @returns {*}
   */
  renderResults({ name, status }, objectiveIndex, resultIndex) {
    const { none, onTrack, inDanger, offTrack } = this.statusMap;
    const { submitClicked } = this.state;

    return (
      <ResultsRow key={`KeyResult${objectiveIndex}${resultIndex}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-6">
              <TextInputField value={name}
                              isInvalid={submitClicked && (name.length < this.validations.minName)}
                              placeholder="Result name"
                              label="Result name"
                              margin={0}
                              width="95%"
                              onChange={e => this.onResultNameChanged(e.target.value, objectiveIndex, resultIndex) } />
            </div>

            <div className="col-12 col-sm-6">
              <div className="d-flex flex-row justify-content-between mt-3 mt-sm-0">
                <div>
                  <Select width={150}
                          value={status}
                          onChange={event => this.onStatusChanged(event.target.value, objectiveIndex, resultIndex)}>
                    <option value={none.value}>{none.label}</option>
                    <option value={onTrack.value}>{onTrack.label}</option>
                    <option value={inDanger.value}>{inDanger.label}</option>
                    <option value={offTrack.value}>{offTrack.label}</option>
                  </Select>
                </div>
                <span onClick={() => this.onDeleteResultClicked(objectiveIndex, resultIndex)}
                      className="red-text clickable">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </ResultsRow>
    );
  }

  /**
   * Renders an objective.
   *
   * @param name
   * @param results
   * @param objectiveIndex
   * @returns {*}
   */
  renderObjective({ name, results }, objectiveIndex) {
    const { objectives, deleteDialogIndex, submitClicked } = this.state;

    return (
      <div key={`ObjectiveContainer${objectiveIndex}`}
           className="background-bottom white-background mx-auto"
           style={{
             borderRadius: '4px',
           }}>
        <div>
          <div className="d-flex flex-row justify-content-between dark-blue-background white-text py-3 mt-3 "
               style={{
                 borderRadius: '4px 4px 0 0',
               }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-5 col-lg-4 objective-name-input">
                  <TextInputField value={name}
                                  isInvalid={submitClicked && (name.length < this.validations.minName)}
                                  placeholder="Objective name"
                                  label="Objective name"
                                  margin={0}
                                  marginTop={3}
                                  onChange={e => this.onObjectiveNameChanged(e.target.value, objectiveIndex) } />
                </div>

                <div className="col-0 col-sm-1 col-md-3 col-lg-5"></div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-right">
                  <div className="d-flex flex-column flex-sm-row mt-3 mt-sm-0">
                    <Button
                      style={{
                        width: '100%'
                      }}
                      className="px-3"
                      onClick={() => this.onAddResultClicked(objectiveIndex)}
                      appearance="dark">Add Result</Button>
                    <i onClick={() => this.setState({ deleteDialogIndex: objectiveIndex })}
                       className="d-none d-sm-block delete-objective-icon clickable fa fa-times ml-3" />

                    <Button
                      style={{
                        width: '100%'
                      }}
                      className="d-sm-none mt-3"
                      onClick={() => this.setState({ deleteDialogIndex: objectiveIndex })}
                      appearance="danger">Delete</Button>

                    <Dialog
                      isShown={deleteDialogIndex === objectiveIndex}
                      title="Delete Objective"
                      onCloseComplete={() => this.setState({ deleteDialogIndex: null })}
                      onConfirm={() => this.onDeleteObjectiveClicked()}
                      confirmLabel="Delete"
                    >
                      Are you sure you want to delete the objective: {objectives[deleteDialogIndex] ? objectives[deleteDialogIndex].name : ''}?
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-sm-block light-gray-text">
            <ResultsRow>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <span className="results-header">Results</span>
                  </div>
                  <div className="col-sm-6">
                    <span className="results-header">Status</span>
                  </div>
                </div>
              </div>
            </ResultsRow>
          </div>
          {
            results.map((result, resultIndex) => this.renderResults(result, objectiveIndex, resultIndex))
          }
        </div>
      </div>
    );
  }

  /**
   * Adds an additional objective.
   * @returns {*}
   */
  onAddObjectiveClicked() {
    const { objectives } = this.state;

    if (objectives.length >= this.validations.maxCount) {
      return toaster.danger(this.errors.tooManyObjectives);
    }

    objectives.push({
      name: '',
      results: [],
    });

    this.setState({ objectives });
  }

  /**
   * Adds an empty result to an objective given the total amount has not reached maximum.
   *
   * @param objectiveIndex
   */
  onAddResultClicked(objectiveIndex) {
    const { objectives } = this.state;

    if (objectives[objectiveIndex].results.length >= this.validations.maxCount) {
      return toaster.danger(this.errors.tooManyResults);
    }

    objectives[objectiveIndex].results.push({ name: '', status: this.statusMap.none.value });

    this.setState({ objectives });
  }

  /**
   * Objective name changed event handler.
   *
   * @param {string} value
   * @param {int} objectiveIndex
   * @param {int} resultIndex
   */
  onObjectiveNameChanged(value, objectiveIndex) {
    const { objectives } = this.state;

    objectives[objectiveIndex].name = value;

    this.setState({ objectives });
  }

  /**
   * Result name changed event handler.
   *
   * @param {string} value
   * @param {int} objectiveIndex
   * @param {int} resultIndex
   */
  onResultNameChanged(value, objectiveIndex, resultIndex) {
    const { objectives } = this.state;

    objectives[objectiveIndex].results[resultIndex].name = value;

    this.setState({ objectives });
  }

  /**
   * Deletes am objective.
   *
   * Clears the delete dialog index to close the delete confirmation dialog.
   */
  onDeleteObjectiveClicked() {
    const { objectives, deleteDialogIndex } = this.state;

    objectives.splice(deleteDialogIndex, 1);

    this.setState({ objectives, deleteDialogIndex: null });
  }

  /**
   * Deletes a result.
   *
   * @param {int} objectiveIndex
   * @param {int} resultIndex
   */
  onDeleteResultClicked(objectiveIndex, resultIndex) {
    const { objectives } = this.state;

    objectives[objectiveIndex].results.splice(resultIndex, 1);

    this.setState({ objectives });
  }

  /**
   * Objective result status changed event handler.
   *
   * Parses the value to be a number because in a select it is a string by default.
   *
   * @param {string} value
   * @param {int} objectiveIndex
   * @param {int} resultIndex
   */
  onStatusChanged(value, objectiveIndex, resultIndex) {
    const { objectives } = this.state;

    objectives[objectiveIndex].results[resultIndex].status = parseInt(value, 10);

    this.setState({ objectives });
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { objectives } = this.state;

    return (
      <div className="text-left">
        {
          objectives.map((objective, index) => this.renderObjective(objective, index) )
        }

        <div className="full-width-item mx-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto my-4 mr-3"
            style={{
              width: '80px'
            }}>Save</Button>
        </div>
      </div>
    );
  }

  /**
   * Component renderer.
   *
   * @returns {*}
   */
  render() {
    if (!this.state.objectives) {
      return <PageLoader />
    }

    return (
      <div>
        {this.renderTopContent()}
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(OkrsEditPage);
