import React, { Component } from 'react';

class PageTopContent extends Component {
  render() {
    const { children, title, subTitle } = this.props;

    return (
      <div className="dark-blue-background pt-5 pb-4 d-flex justify-content-center align-items-center"
           style={{
             width: '100%',
           }}>
        <div className="page-top-content d-flex flex-row px-3 mb-3">
          <div>
            {
              title &&
              <h3 className="page-title">
                {title}
              </h3>
            }
            {
              subTitle &&
              <p className="page-subtitle m-0">
                {subTitle}
              </p>
            }
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default PageTopContent;
