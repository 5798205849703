import React, { Component } from 'react';
import { Dialog} from 'evergreen-ui';
import ApiClient from '../../../ApiClient.js';

class DeactivateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_shown: false,
    };
  }

  showModal() {
    this.setState({modal_shown: true});
  }

  render() {
    return (
      <Dialog
          style={{height: 300}}
          width={375}
          isShown={this.state.modal_shown}
          title={this.props.title}
          onCloseComplete={() => this.setState({modal_shown: false})}
          onConfirm={() => {
            ApiClient.post(this.props.submitUrl, {active: false}).then(this.props.submitSuccess);
            this.setState({modal_shown: false});
          }}
          confirmLabel="Deactivate"
        >
          <div style={{fontSize: 14, fontWeight: 500}}>{this.props.prompt}</div>
      </Dialog>
    );
  }
}

export default DeactivateModal;