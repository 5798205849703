import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, Label, Textarea, toaster, Checkbox } from 'evergreen-ui';
import ApiClient from '../../ApiClient.js';
import Loader from 'react-loader-spinner';

// shared components
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';
import { analyticsPage } from '../../utils.js';

class PostEditPage extends Component {
  
  constructor(props) {
    super(props);

    const creating = !this.props.post_id;

    this.state = {
      title: '',
      content: '',
      pinned: false,
      announcement: false,
      loading: false,
      pageLoading: !creating,
      creating: creating
    };
  }

  componentDidMount() {
    analyticsPage("post_edit");

    if (!this.isCreating()) {
      ApiClient.get(`/posts/` + this.props.post_id)
        .then(data => this.setState({
          content: data.content,
          title: data.title,
          pinned: data.pinned,
          announcement: data.announcement,
          pageLoading: false
        }))
        .catch(err => this.onError(err));
    }
  }


  errorMessage = 'An error occurred';

  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    title: 'Name is not valid',
    content: 'Content is not valid',
  };

  /**
   * Validation values.
   */
  validations = {
    minLength: 1,
    maxContent: 10000,
    maxTitle: 100
  };

  validators = {
    title: (title) => title.length <= this.validations.minLength || title.length > this.validations.maxTitle,
    content: (content) => content.length <= this.validations.minLength || content.length > this.validations.maxContent,
  };

  /**
   * Determines if the post is being created or edited.
   *
   * @returns {*}
   */
  isCreating() {
    return !this.props.post_id;
  }

  /**
   * Submits the team/groups's info.
   *
   * Sets the submitClicked flat to true.
   */
  onSubmit() {
    const {
      title, content, pinned, loading, announcement
    } = this.state;
    const payload = {
      title, content, pinned, announcement
    };
    const url = this.props.post_id ? `/posts/${this.props.post_id}` : '/posts';

    this.setState({ submitClicked: true });

    if (this.validators.title(title)) {
      return toaster.danger(this.errors.title);
    }

    if (this.validators.content(content)) {
      return toaster.danger(this.errors.content);
    }

    if (loading) {
      return;
    } else {
      this.setState({ loading: true });
    }

    ApiClient.post(url, payload)
      .then((data) => this.onAnnouncementUpdated(data))
      .catch(err => this.onError(err));
  }

  /**
   * Announcement updated event handler.
   *
   * Navigates to the announcements page.
   */
  onAnnouncementUpdated(data) {
    this.setState({ loading: false });
    this.props.history.push(`/post/${data.id}`);
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errorMessage);
    this.setState({ loading: false });
  }

  /**
   * Renders the fields used in the general section.
   *
   * Only renders the name text field if a new team/group is being created.
   */
  renderGeneralSection() {
    const {
      title, content, submitClicked = [],
    } = this.state;

    return (
      <div className="container-fluid">
        {this.props.admin && 
          <div>

            <div className="row">
              <div className="col-12 col-md-6">
                <Label
                  marginBottom={4}
                  display="block"
                >
                  Mark as Announcement?
                </Label>
                <Checkbox label="Announcement" checked={this.state.announcement}
                          onChange={(e) => this.setState({announcement: e.target.checked})}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Label
                  marginBottom={4}
                  display="block"
                >
                  Pin Post
                </Label>
                <Checkbox label="Pin" checked={this.state.pinned}
                          onChange={(e) => this.setState({pinned: e.target.checked})}/>
              </div>
            </div>
            </div>}

            <div className="row">
              <div className="col-12 col-md-6">
                <TextInputField
                  isInvalid={submitClicked && this.validators.title(title)}
                  label="Name"
                  defaultValue={title}
                  placeholder="Ask a question, share an accomplishment, plan an outing!"
                  onChange={event => this.setState({title: event.target.value})}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Label
                  htmlFor="content"
                  marginBottom={4}
                  display="block"
                >
                  Content
                </Label>

                <Textarea
                  id="description"
                  isInvalid={submitClicked && this.validators.content(content)}
                  style={{marginBottom: 24, height: 200}}
                  placeholder="I wish that penguins could fly"
                  defaultValue={content}
                  maxLength={this.validations.maxContent}
                  onChange={event => this.setState({content: event.target.value})}
                />
              </div>
            </div>
         
      </div>
    );
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { loading } = this.state;

    return (
      <div className="text-left mx-auto background-bottom pt-4">
          {this.renderGeneralSection()}

        <div className="full-width-item mx-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto my-4 mr-3"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40}/>
            }
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Renders the team edit page given that the team and self object are loaded.
   *
   * Updates the point of contact me state property onc all data is loaded. Sets to true of the team has a point of contact and that is the logged on person.
   * @returns {*}
   */
  render() {
    if (this.state.pageLoading) {
      return <PageLoader />
    }

    return (
      <div>
        <PageTopContent title={this.state.creating ? "Create Post" : "Edit Post"} />
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(PostEditPage);
