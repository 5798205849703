import React, { Component } from 'react';
import '../styles/new_hires.scss';
import { Link } from 'react-router-dom';
import { analyticsTrack } from '../../../utils.js';

class PeopleCard extends Component {
  render() {
    const person = this.props.person;

    return (
      <Link 
        onClick={() => analyticsTrack('peopleCard_personClick', {path: window.location.pathname, person: person.email})}
        className="new-hire-link router-link rl-black" 
        to={"/people/" + person.email}>
        <div key={"ppl_" + person.email} className="new-hire-container grow p-3 mb-3 text-left">
          <div className="row" style={{ maxWidth: 400, minHeight: 112}}>
            <div className="col-12">
              <div className="d-flex flex-row">
              <div className="new-hire-text-container">
                    <h5 className="mb-1">{person.first_name} {person.last_name}</h5>
                  <p>{person.role}</p>
                  <p>{person.location}</p>
                  {this.props.children}
                </div>
                <div className="new-hire-picture-container d-flex align-items-center ml-auto">
                  <img src={person.photo} alt="profile"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default PeopleCard;