import React, { Component } from 'react';

class ResourcesRow extends Component {

  render() {
    return (
      <div className="d-flex flex-row justify-content-between p-3 p-sm-2 p-md-3 light-gray-border-color category-resource-container"
           style={{
             borderTopWidth: '1px',
             borderTopStyle: 'solid',
           }}>
        {this.props.children}
      </div>
    );
  }
}

export default ResourcesRow;
