import React, { Component } from 'react';
import Button from '../../shared/components/Button.react';
import ApiClient from '../../../ApiClient.js';
import { isDemo } from '../../../utils';

class TeamInfo extends Component {
  render() {
    if (!this.props.team) {return null;}
    var joinLeaveButton = null;
    if (!this.props.team.is_team && !isDemo()) {
      joinLeaveButton = 
        <Button 
          style={{fontSize: 12, width: 100, height: 30, marginTop: 15}}
          className="px-0"
          appearance={this.props.team.in_group ? "secondary" : "primary"}
          onClick={() => ApiClient.post(
            "/people/" + this.props.self.email + "/groups/" + this.props.team.id, 
            {in_group: !this.props.team.in_group}
          ).then(() => this.props.onJoinLeave())}>
          {this.props.team.in_group ? "Leave Group" : "Join Group" }
        </Button>
    }
    return (
      <div className="team-info-container ml-md-2 d-flex flex-column justify-content-center align-items-start">
        <div className="team-info-header mb-3 mr-md-4">
          {this.props.team.name}
          {(this.props.team.in_group || this.props.self.is_company_admin) && !isDemo() &&
            <img className="team-edit-icon ml-2"
              alt="edit"
              onClick={() => this.props.openEdit()}
              src="/img/edit.png" />
          }
        </div>
        <div className="team-info-description">
          {this.props.team.description}
        </div>
        {joinLeaveButton}
      </div>
    );
  }
}

export default TeamInfo;
