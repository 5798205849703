import React, { Component } from 'react';

class Container extends Component {
  /**
   * Renders a styled container.
   */
  render() {
    return (
      <div style={{
        borderRadius: '5px',
      }}
      className="white-background p-2 py-3">
        {this.props.children}
      </div>
    );
  }
}

export default Container;
