import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.scss';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

window.Intercom("boot", {
  app_id: "jf365a09"
});

if (!window.location.hostname.includes("localhost") && !window.location.hostname.includes("staging")) {
  Sentry.init({
    dsn: "https://5d22f0f1eaef4367b96744d995466cd6@sentry.io/1359489"
  });
}

ReactDOM.render(
  <Router>
    <App />
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
