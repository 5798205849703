import React, { Component } from 'react';
import { InstantSearch, Hits, Index, SearchBox, Configure, Highlight} from 'react-instantsearch-dom';
import './styles/search.scss';
import { Link } from 'react-router-dom';
import { analyticsPage, analyticsTrack } from '../../utils';
import * as moment from 'moment';

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };

    this.teamResult = this.teamResult.bind(this);
    this.profileResult = this.profileResult.bind(this);
  }

  componentDidMount() {
    this.setState({query: this.props.query});
    analyticsPage("search");
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.setState({query: this.props.query});
    }
  }

  profileResult({hit}) {
    return (
      <Link 
        onClick={() => analyticsTrack('search_personClick', {email: hit.email, query: this.state.query})}
        to={"/people/" + hit.email}
        style={{ textDecoration: 'none' }}>
        <div className="profile-result-container grow d-flex flex-row align-items-center">
          <img className="profile-result-picture ml-3" src={hit.photo} alt="profile-result" />
          <div className="profile-result-text d-flex flex-column text-left ml-3">
            <h5>{hit.first_name} {hit.last_name}</h5>
            <p>{hit.role}</p>
          </div>
        </div>
      </Link>
    );
  }

  teamResult({hit}) {
    return (
      <Link
        onClick={() => analyticsTrack('search_groupClick', {group: hit.name, group_id: hit.objectID, query: this.state.query})} 
        to={"/team/" + hit.objectID}
        style={{ textDecoration: 'none' }}>
        <div className="team-result-container grow d-flex flex-column justify-content-center text-left pl-3">
          {hit.name}
          <p>{hit.description}</p>
        </div>
      </Link>
    );
  }

  postResult({hit}) {
    const formattedDate = moment.unix(hit.posted).fromNow();

    return (
      <Link 
        to={"/post/" + hit.final_id}
        style={{textDecoration: 'none', color: 'black'}}>
      <div className="d-flex flex-column align-items-start" style={{textAlign: 'left'}}>
        <div className="d-flex flex-row align-items-center">
        {hit.title && <Highlight className="search-post-title" attribute="title" hit={hit} />}
        {!hit.title && <div style={{fontWeight: 600}}>(Comment) {hit.parent_title}</div>}
         &nbsp;- {hit.author} &nbsp;<b style={{fontSize: 12}}>{formattedDate}</b></div>
            <Highlight attribute="content" tagName="span" nonHighlightedTagName="span" hit={hit} />
      </div>
      </Link>
    );
  }

  render() {
    if (!this.props.self || !this.props.searchKey) return null;
    const indexSuffix =  (window.location.host.includes("localhost") || window.location.host.includes("staging")) ? "dev" : "prod";
    let results_label = this.state.query ? <p>Search results for:</p> : <div />;

    return (
      <InstantSearch
        appId="XS946XNLUJ"
        apiKey={this.props.searchKey}
        indexName={"person_index_" + indexSuffix}
        searchState={{query: this.state.query}}
        onSearchStateChange={(searchQuery) => this.setState({query: searchQuery.query})}>
        <div className="search-top-container d-flex justify-content-center py-4">
          <div className="search-top-container-content d-flex flex-column justify-content-flex-start pl-5 page-top-content">
            {results_label}
            <SearchBox style={{backgroundColor: 'blue'}}/>
          </div>
        </div>
        <div className="all-results-container m-auto">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-xl-6">
                  <div className="profile-results-container pr-xl-4">
                    <h4>People</h4>
                    <Configure hitsPerPage={8} facetFilters={"company:" + this.props.self.company.id} filters={"active:true"} />
                    <Hits hitComponent={this.profileResult}/>
                  </div>
                </div>

              <div className="col-12 col-xl-6">

                <div className="team-results-container">
                  <h4>Groups & Teams</h4>
                  <Index indexName={"group_index_" + indexSuffix}>
                    <Configure hitsPerPage={4} facetFilters={"company:" + this.props.self.company.id} filters={"active:true"}/>
                    <Hits hitComponent={this.teamResult}  />
                  </Index>
                </div>
              </div>
            </div>
            <div>
            <h4>Posts</h4>
            <div className="post-results-container">
                  <Index indexName={"post_index_" + indexSuffix}>
                    <Configure hitsPerPage={25} facetFilters={"company:" + this.props.self.company.id} filters={"active:true"}/>
                    <Hits hitComponent={this.postResult}  />
                  </Index> 
            </div>
            </div>
          </div>
        </div>
        
      </InstantSearch>
    );
  }
}

export default SearchPage;
