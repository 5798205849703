import React, { Component } from 'react';

class ResultsRow extends Component {

  render() {
    return (
      <div className="d-flex flex-row justify-content-between p-3 light-gray-border-color objective-result-container"
           style={{
             borderTopWidth: '1px',
             borderTopStyle: 'solid',
           }}>
        {this.props.children}
      </div>
    );
  }
}

export default ResultsRow;
