import Cookies from 'js-cookie';

export const getStorage = (key) => {
    return window.localStorage.getItem(key);
}

export const setStorage = (key, value) => {
    if (value) {
        window.localStorage.setItem(key, value);
    }
}

export const clearStorage = () => {
    window.localStorage.clear();
}

export const getAccessToken = () => {
    return Cookies.get('access_token');
}

export const setAccessToken = (access_token) => {
    clearStorage();
    Cookies.set('access_token', access_token, {expires: 30});
}

export const ordinalSuffix = (i) => {
  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}

export const dayName = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return days[date.getDay()];
}

export const equalDate = (d1, d2) => {
  return d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export const getDate = (dateString) => {
    var dateArray = dateString.split("-");
    var year = dateArray[0];
    var month = parseInt(dateArray[1], 10) - 1;
    var date = dateArray[2];
    return new Date(year, month, date);
}

export const formatDate = (inputDate) => {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime()) && inputDate) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

export const getFullName = (person) => {
    return person.first_name + " " + person.last_name;
}

export const analyticsPage = (name) => {
    window.Intercom("update");
    if (window.analytics) {
        window.analytics.page(name);
    }
}

export const analyticsIdentify = (id, traits) => {
    if (window.analytics) {
        window.analytics.identify(id, traits);
    }
}

export const analyticsTrack = (event, properties) => {
    if (window.analytics) {
        window.analytics.track(event, properties);
    }
}

export const isDemo = () => {
    return window.location.hostname.split('.')[0] === 'demo';
}