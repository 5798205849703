import React, { Component } from 'react';

class Button extends Component {
  /**
   * Renders a button with a default type 'default'.
   */
  render() {
    const { children, className, appearance = 'primary'} = this.props;

    return (
      <div {...this.props}
           className={className + ` button ${appearance}-button d-flex align-items-center justify-content-center`}>
        {children}
      </div>
    );
  }
}

export default Button;
