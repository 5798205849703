import React, { Component } from 'react';
import { ordinalSuffix } from '../../../utils.js';
import '../styles/events.scss';
import EventCard from './EventCard.react';
import Title from '../../shared/components/Title.react';

class EventView extends Component {
   /**
   * Renders today's birthdays.
   *
   * @returns {*}
   */
  renderBirthdays() {
    const { birthdays } = this.props;
    const hasBirthdaysToday = Array.isArray(birthdays) && birthdays.length > 0;

    return (
      <div>
        {
          hasBirthdaysToday &&
              birthdays.map((birthday, index) => {
                const content = `${birthday.first_name} ${birthday.last_name}'s Birthday!`;

                return (
                  <div key={`BirthdayCardKey${index}`}>
                    <EventCard 
                      type='birthday'
                      item={birthday}
                      content={content}>
                      <span role="img" aria-label="birthday">🥳</span>
                    </EventCard>
                  </div>
                );
              })
        }

      </div>
    );
  }

  /**
   * Renders new hires.
   * @returns {*}
   */
  renderNewHires() {
    return (
      <div className="d-flex flex-column align-items-center events-container white-container mt-4" style={{height: 'fit-content'}}>
        <div className="events-section">
          <div className="events-header px-3 d-flex align-items-center">
            <Title>New Hires</Title>
          </div>

          <div className="d-flex flex-column text-left align-items-stretch">
            <div className="event-section-content">
              {
                this.props.newHires.map((newHire, index) => {
                  const content = `${newHire.first_name} ${newHire.last_name}`;

                  return (
                    <div key={`NewHireCardKey${index}`}>
                      <EventCard
                        type='new_hire'
                        item={newHire}
                        content={content}>
                        <span role="img" aria-label="newhire">👋</span>

                      </EventCard>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders today's anniversaries.
   *
   * @returns {*}
   */
  renderAnniversaries() {
    const { anniversaries } = this.props;
    const hasAnniversariesToday = Array.isArray(anniversaries) && anniversaries.length > 0;

    return (
      <div>
        {
          hasAnniversariesToday &&
          anniversaries.map((anniversary, index) => {
            const content = `${anniversary.first_name} ${anniversary.last_name}'s ${ordinalSuffix(this.props.date.getFullYear() - new Date(anniversary.joined).getFullYear())} Anniversary!`;

            return (
              <div key={`AnniversaryCardKey${index}`}>
                <EventCard
                  type='anniversary'
                  item={anniversary}
                  content={content}>
                  <span role="img" aria-label="newhire">🏅</span>
                </EventCard>
              </div>
            )
          })
        }
      </div>
    );
  }

  /**
   * Renders birthdays and anniversaries.
   * @returns {*}
   */
  renderEvents() {
    return (
      <div className="d-flex flex-column align-items-center events-container white-container mt-4" style={{height: 'fit-content'}}>
        <div className="events-section">
          <div className="events-header px-3 d-flex align-items-center">
            <Title>{this.props.date.toLocaleString('en-US', {weekday: 'long', month: 'long', day: 'numeric'})}</Title>
          </div>

          <div className="d-flex flex-column text-left align-items-stretch">
            <div className="event-section-content">
              {this.renderBirthdays()}
              {this.renderAnniversaries()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders birthdays, anniversaries, and new hires.
   * @returns {*}
   */
  render() {
    return (
      <div className="d-flex flex-column align-items-center align-items-md-start flex-md-row justify-content-md-around flex-lg-column justify-content-lg-center">
        {(this.props.birthdays.length > 0 || this.props.anniversaries.length > 0) && this.renderEvents()}
        {this.props.newHires.length > 0 && this.renderNewHires()}
      </div>
    );
  }
}

export default EventView;
