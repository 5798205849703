import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './styles/settings.scss';

// shared components
import PageLoader from '../shared/components/PageLoader.react';
import EditResources from '../shared/components/resources/EditResources.react';
import { parseResourceLinks, resourceLinksNotValid, resourceTitlesNotValid } from '../shared/utils';
import { toaster } from 'evergreen-ui';
import ApiClient from '../../ApiClient';
import Button from '../shared/components/Button.react';
import Loader from 'react-loader-spinner';

class ResourcesTab extends Component {
  state = {
    resources: null,
  };

  validations = {
    minTitle: 1,
  };
  
  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    resourceTitles: 'Resource titles are not valid',
    resourceLinks: 'Resource links are not valid',
  };
  
  constructor(props) {
    super(props);

    ApiClient.get(`/home/resources`)
      .then(data => this.onDataRetrieved(data))
      .catch(err => this.onError(err));
  }

  /**
   * Data retrieved handler.
   *
   * @param resources Resources
   */
  onDataRetrieved(resources) {
    this.setState({ resources });
  }

  /**
   * Submits the company's settings and resrouces.
   *
   * Adds valid new custom fields.
   * Validates resources (links and titles).
   */
  onSubmit() {
    let { resources } = this.state;

    this.setState({ submitClicked: true });

    if (resourceTitlesNotValid(resources, this.validations.minTitleLength)) {
      return toaster.danger(this.errors.resourceTitles)
    }

    if (resourceLinksNotValid(resources)) {
      return toaster.danger(this.errors.resourceLinks)
    }

    // parse resource links
    resources = parseResourceLinks(resources);

    const { loading } = this.state;
    if (loading) {
      return;
    } else {
      this.setState( { loading: true });
    }

    ApiClient.post(`/home/resources`, resources)
      .then(() => this.onResourcesChanged())
      .catch(err => this.onError(err));
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { loading: false });
  }

  /**
   * Profile updated event handler.
   *
   * Navigates to the profile page.
   */
  onResourcesChanged() {
    this.setState( { loading: false });
    this.props.onSettingsChange();
    this.props.history.push('');
  }

  /**
   * Updates resources state.
   * @param resources
   */
  updateResources(resources) {
    this.setState({ resources });
  }

  /**
   * Renders the resources tab content.
   */
  renderContent() {
    return (
      <div className="text-left">
        <EditResources resources={this.state.resources}
                       updateResources={resources => this.updateResources(resources)}
                       submitClicked={this.state.submitClicked} />
        {this.renderSaveButton()}
      </div>
    );
  }

  /**
   * Renders the save button.
   */
  renderSaveButton() {
    return(
      <div className="full-width-item mx-auto">
        <Button
          onClick={() => this.onSubmit()}
          appearance="primary"
          className="ml-auto my-4 mr-3"
          style={{
            width: '80px'
          }}>
          {
            !this.state.loading &&
            'Save'
          }
          {
            this.state.loading &&
            <Loader type="ThreeDots" color="#fff" width={40} />
          }
        </Button>
      </div>
    );
  }

  render() {
    const { resources } = this.state;

    if (!resources) {
      return <PageLoader />;
    }

    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

export default withRouter(ResourcesTab);
