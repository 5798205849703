import React, { Component } from 'react';
import { InstantSearch, Hits, Configure, connectSearchBox} from 'react-instantsearch-dom';
import { TextInputField } from 'evergreen-ui';
import '../styles/people_select.scss';
import algoliasearch from 'algoliasearch';

class GroupSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: null,
      query: '',
    };

    this.groupResult = this.groupResult.bind(this);
  }

  componentDidMount() {
    this.setState({selectedGroup: this.props.initialGroup});
  }

  selectedGroup(group) {
    this.props.onSelectGroup(group);
    this.setState({selectedGroup: group});
  }

  groupResult({hit}) {
    return (
      <div className="profile-result" onClick={() => this.selectedGroup(hit)}>
          <div><span>{hit.name}</span></div>
      </div>
    );
  }

  render() {
    if (!this.props.searchKey) return null;

    const indexSuffix =  (window.location.host.includes("localhost") || window.location.host.includes("staging")) ? "dev" : "prod";
    
    const MySearch = connectSearchBox(({value, refine}) => {return(<TextInputField 
      label={this.props.label} 
      className="search-box"
      value={value} 
      placeholder={this.state.selectedGroup ? this.state.selectedGroup.name : "None"}
      onChange={event => {refine(event.target.value);}}
   />)});

    const algoliaClient = algoliasearch('XS946XNLUJ', this.props.searchKey, {
      _useRequestCache: true,
    });

    const searchClient = {
      search(requests) {
        const shouldSearch = requests[0].params.query !== '';
        if (shouldSearch) {
          return algoliaClient.search(requests);
        }
        return Promise.resolve({
          results: [{ hits: [] }],
        });
      },
      searchForFacetValues: algoliaClient.searchForFacetValues,
    };

    const active_prop = this.props.active ? this.props.active : "true";
    const is_team_filter = this.props.is_team == null ? '' : ' AND is_team:' + this.props.is_team;

    return (
      <div style={this.props.style} className="people-selector">
        <InstantSearch
          searchClient={searchClient}
          indexName={"group_index_" + indexSuffix}>
          <div className="d-flex flex-row">
            <div style={{width: '100%'}}>
              <MySearch />
            </div>
            {this.props.clear && 
              <i onClick={() => this.selectedGroup({name: 'None', objectId: null})} className="fas fa-times-circle clear-button"></i>
            }
          </div>

          <Configure hitsPerPage={4} filters={"active:" + active_prop + is_team_filter} />
          <Hits hitComponent={this.groupResult} />
        </InstantSearch>
      </div>
     
    );
  }
}

export default GroupSelector;