import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Tablist, Tab } from 'evergreen-ui';
import './styles/settings.scss';

// shared components
import PageTopContent from '../shared/components/PageTopContent.react';
import SettingsTab from './SettingsTab';
import ResourcesTab from './ResourcesTab';
import { capitalizeFirstLetter } from '../shared/utils';
import { analyticsTrack } from '../../utils';

class AdminPage extends Component {
  state = {
    selectedTabIndex: 0,
  };

  /**
   * List of all available tabs.
   */
  tabList = [];

  /**
   * Names of all available tabs.
   */
  tabNames = {
    settings: 'settings',
    resources: 'resources',
  };

  constructor(props) {
    super(props);

    this.makeTabs();

    let selectedTabIndex = 0;

    this.tabList.find((tab, index) => {
      if (tab === this.props.tab) {
        selectedTabIndex = index;

        return true;
      }

      return false;
    });

    this.state = { selectedTabIndex };
  }

  /**
   * Constructs the tabs based on the available tabs names.
   */
  makeTabs() {
    this.tabList = [
      this.tabNames.settings,
      this.tabNames.resources,
    ];
  }

  /**
   * Tab changed event handler.
   *
   * @param index
   */
  onTabChanged(index) {
    analyticsTrack('admin_tabChanged', {old_tab: this.tabList[this.state.selectedTabIndex], new_tab: this.tabList[index]});
    this.setState({ selectedTabIndex: index });
    this.props.history.push(`/admin/${this.tabList[index]}`);
  }

  /**
   * Renders the top content.
   */
  renderTopContent() {
    const title = 'Admin Page';

    return (
      <PageTopContent
        title={title} />
    );
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { selectedTabIndex } = this.state;

    if (selectedTabIndex === this.tabList.indexOf(this.tabNames.settings)) {
      return <SettingsTab self={this.props.self}
                          searchKey={this.props.searchKey}
                          onSettingsChange={this.props.onSettingsChange} />;
    }
    if (selectedTabIndex === this.tabList.indexOf(this.tabNames.resources)) {
      return <ResourcesTab onSettingsChange={this.props.onSettingsChange}/>;
    }
  }

  render() {
    return (
      <div>
        {this.renderTopContent()}
        <Tablist marginBottom={16} marginTop={16} flexBasis={240} marginRight={24}>
          {this.tabList.map((tab, index) => (
            <Tab
              key={tab}
              id={tab}
              onSelect={() => this.onTabChanged(index)}
              isSelected={index === this.state.selectedTabIndex}
              aria-controls={`panel-${tab}`}
            >
              <h4 className="my-2">{capitalizeFirstLetter(tab)}</h4>
            </Tab>
          ))}
        </Tablist>
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(AdminPage);
