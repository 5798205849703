import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { analyticsTrack } from '../../../utils';

class EventCard extends Component {
  /**
   * Renders a card with content.
   */
  render() {
    const {
      children,
      content,
      item: {
        email, photo, role
      }, 
      type,
    } = this.props;

    return (
      <Link onClick={() => analyticsTrack('homepage_eventClicked', {email: email, content: content, event_type: type})}
            className="clickable no-decoration event-card-link"
            to={"/people/" + email}>
        <div className="d-flex flex-row justify-content-center align-items-center px-4 white-background event-card-container">
          <img src={photo} className="events-photo-icon" alt="profile" />

          <div className="event-detail-content d-flex px-3 flex-column">
            <span style={{ lineHeight: '16px' }}>{content}</span>
            <div className="small-text light-gray-text" style={{ marginTop: '3px' }}>{role}</div>
          </div>

          {children}
        </div>
      </Link>
    );
  }
}

export default EventCard;
