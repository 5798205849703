import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar.react';
import '../styles/header.scss';
import '../styles/search.scss';

class PageHeader extends Component {
  /**
   * Error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
  }

  render() {
    if (!this.props.show) return '';
    if (!this.props.self) return <div className="dark-blue-background pb-5" style={{height: 75}}/>;
    
    const profileCircle = (
      <Link className="nav-item" to={"/people/" + this.props.self.email}>
        <img src={this.props.self.photo} className="top-bar-profile-circle" alt="self" />
      </Link>
    );

      return (
        <div className="nav-container dark-blue-background pb-3" style={{
          width: '100%',
        }}>
          <nav className="navbar navbar-expand-sm navbar-dark my-md-0 mx-md-auto" style={{minHeight: 66}}>
            {!this.props.min &&
              <Link className="navbar-brand" to="/">
                <img src={this.props.self.company.logo || "/img/trestle-logo.png"} style={{ height: 40 }} alt="company-icon"></img>
              </Link>
            }
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto" style={{paddingLeft: 45}}>
                <li className="d-sm-none nav-item">
                  {profileCircle}
                </li>

                <li className={`nav-item ${this.props.selected === '/' ? 'active' : ''}`}>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>

                <li className={`nav-item ${this.props.selected === '/all' ? 'active' : ''}`}>
                  <Link className="nav-link" to="/all">
                    Everyone
                  </Link>
                </li>

                <li className={`nav-item ${this.props.selected === '/teams' ? 'active' : ''}`}>
                  <Link className="nav-link" to="/teams">
                    Teams
                  </Link>
                </li>

                <li className={`nav-item ${this.props.selected === '/groups' ? 'active' : ''}`}>
                  <Link className="nav-link" to="/groups" >
                    Groups
                  </Link>
                </li>

                {
                  this.props.self &&
                  this.props.self.is_company_admin === true &&
                  <li className={`nav-item ${this.props.selected.includes('/admin') ? 'active' : ''}`}>
                    <Link className="nav-link" to="/admin">
                      Admin
                    </Link>
                  </li>
                }

              </ul>
              {!this.props.min && 
                <div className="d-none d-md-block my-4 my-lg-0">
                  <SearchBar search={this.props.search} class_prefix="top-bar" />
                </div>
              }
                
              <li className={`d-none d-sm-block ml-3 nav-item ${this.props.selected === '/groups' ? 'active' : ''}`}>
                {profileCircle}
              </li>
              
            </div>
          </nav>
        </div>
      );
  }
}

export default PageHeader;
