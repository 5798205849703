import React, { Component } from 'react';
import ApiClient from '../../../ApiClient.js';
import '../styles/new_hires.scss';
import { getStorage, setStorage } from '../../../utils.js';
import PeopleCard from './PeopleCard.react';
import PageLoader from './PageLoader.react.js';

class PeopleGroupView extends Component {
  constructor(props) {
    super(props);
    var storagePeople = null;
    if (this.props.cached) {
      storagePeople = JSON.parse(getStorage(this.props.cached));
    }
    this.state = {
      loading: true,
      people: this.props.cached && storagePeople ? storagePeople : [],
    };
  }

  componentDidMount() {
    ApiClient.get(this.props.path).then(
      data => { 
        if (this.props.cached) {
          setStorage(this.props.cached, JSON.stringify(data));
        }
        this.setState({ people: data, loading: false }); 
      }
    );
  }

  render() {
    let newHires = this.state.loading ? <PageLoader /> : <div />;

    if (this.state.people && this.state.people.length) {
      let newHireComponents = this.state.people.map((person, index) => {
        
        let reportComponent;
        if (this.props.show_reports) {
          var reportText = "Individual Contributer"
          if (person.num_reports) {
            reportText = person.num_reports + " Reports";
          }
          reportComponent = 
            <div className="new-hire-reports">
              {reportText}
            </div>;
        }
        
        return (
          <div key={'newHire' + index} className="col-12 col-md-6 col-xl-4" style={{maxWidth: 405}}>
            <PeopleCard person={person} >
              {reportComponent}
            </PeopleCard>
           
          </div>
        );
      });

      const CHUNK_SIZE = 3;
      let chunked = [];
      for (let i = 0; i < newHireComponents.length; i += CHUNK_SIZE) {
        let chunk = newHireComponents.slice(i, i + CHUNK_SIZE);
        chunked.push(chunk);
      }

      newHires =
        <div className="new-hires-all-container d-flex flex-column m-auto justify-content-center" style={this.props.smaller ? {maxWidth: 992} :{}}>
          <h5 className={"new-hires-header m-3 text-left"}>{this.props.header} <span style={{color: 'rgba(51, 51, 51, 0.63)'}}>{this.props.header ? this.state.people.length : null}</span></h5>
          <div className="container-fluid">
            <div className="row">
              {chunked}
            </div>
          </div>
        </div>;
    }

    return newHires;
  }
}

export default PeopleGroupView;