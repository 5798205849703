import React, { Component } from 'react';
import './App.css';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import Homepage from './screens/homepage/Homepage';
import ProfilePage from './screens/profile/ProfilePage';
import ApiClient from './ApiClient.js';
import { getAccessToken, setAccessToken, analyticsIdentify, getFullName, getStorage, setStorage } from './utils.js';
import TeamPage from './screens/team/TeamPage';
import TeamEditPage from './screens/team/TeamEditPage';
import TeamsPage from './screens/teams/TeamsPage';
import AllPage from './screens/people/AllPage';
import PageHeader from './screens/shared/components/PageHeader.react.js';
import SearchBar from './screens/shared/components/SearchBar.react';
import SearchPage from './screens/search/SearchPage';
import LoginPage from './screens/login/LoginPage';
import OnboardingPage from './screens/onboarding/OnboardingPage';
import AdminPage from './screens/admin/AdminPage';
import AnnouncementEditPage from './screens/admin/AnnouncementEditPage';
import OkrsEditPage from './screens/okrs/OkrsEditPage';
import NotFoundPage from './screens/NotFoundPage';
import * as Sentry from '@sentry/browser';
import PostEditPage from './screens/posts/PostEditPage';
import PostPage from './screens/posts/PostPage';
import PageLoader from './screens/shared/components/PageLoader.react';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      self: JSON.parse(getStorage("self")),
      searchKey: null,
    };
    this.onSearch = this.onSearch.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onStart = this.onStart.bind(this);
  }

  componentDidMount() {
    this.onStart();
  }

  onStart() {
    ApiClient.get("/search_key").then((data) => this.setState({searchKey: data}));
    return this.refreshData().then((data) => {
      if (data) {
        Sentry.configureScope((scope) => {
          scope.setUser({"email": data.email});
        });
        analyticsIdentify(data.id, {
          email: data.email, 
          name: getFullName(data), 
          role: data.role,
          company: data.company.name,
          team: data.team
        });
      }
      return data;
    }); 
  }

  refreshData() {
    return ApiClient.get("/self").then((data) => {
      if(data) {
        setStorage("self", JSON.stringify(data)); 
        this.setState({self: data}); 
        return data;
      } else {
        setAccessToken(null);
        if (this.props.history.location.pathname !== "/login") {
          this.props.history.push('/login');
        }
      }
    });
  }

  onSearch(searchQuery) {
    this.props.history.push('/search/' + searchQuery);
  }

  onLogin() {
    this.onStart().then((data) => {
      if (data) {
        data.completed_signup ? this.props.history.push('/') : this.props.history.push('/people/' + data.email + '/edit')
      }});
    return <PageLoader />;
  }

  onSubdomainLogin(hostname_parts) {
    ApiClient.no_auth_get('/login_redirect/' + hostname_parts[0]).then((data) => {
      window.location.replace(data.redirect);
    }).catch(console.error);
    return <PageLoader />;
  }

  render() {
    if (window.location.pathname === '/new_token') {
      const params = new URLSearchParams(this.props.location.search);
      if (params.has("trestle_token")) {
        setAccessToken(params.get("trestle_token"));
        return this.onLogin();
      }
    }

    const hostname_parts = window.location.hostname.split('.');
    const is_subdomain = hostname_parts.length > 2 && hostname_parts[0] !== 'www' && hostname_parts[0] !== 'login' && hostname_parts[1] === 'ontrestle';

    const loginRedirect = (
      <Redirect to={{
        pathname: "/login",
        state: { from: this.props.location }
      }} />
    );
    if ((!getAccessToken() || getAccessToken() === 'null') && this.props.history.location.pathname !== "/login") {
      if (is_subdomain) {
        return this.onSubdomainLogin(hostname_parts);
      }
      return loginRedirect;
    }

    if (this.props.history.location.pathname === "/logout") {
      setAccessToken('');
      if (is_subdomain) {
        return this.onSubdomainLogin(hostname_parts);
      }
      return loginRedirect;
    }

    if (this.props.history.location.pathname === "/login" && is_subdomain) {
      return this.onSubdomainLogin(hostname_parts);
    }

    const isCompanyAdmin = this.state.self && (this.state.self.is_company_admin === true);
    const disabledFields = (this.state.self && this.state.self.company.disabled_fields) ? JSON.parse(this.state.self.company.disabled_fields) : [];
    return (
      <div className="App">
        <PageHeader search={this.onSearch}
                    ref={this.pageHeaderRef}
                    selected={this.props.location.pathname}
                    self={this.state.self}
                    min={this.props.history.location.pathname === '/'}
                    show={this.props.history.location.pathname !== "/login"} />
        {
          this.props.history.location.pathname !== "/search" &&
          this.props.history.location.pathname !== "/" &&
          <div className="d-md-none pb-4 dark-blue-background dark-blue-border"
               style={{
                 width: '100%',
               }}>
            <SearchBar search={this.onSearch} class_prefix="top-bar" />
          </div>
        }
        <Switch>
          <Route path="/login"
                 render={() => <LoginPage next={this.onLogin} />} />
          <Route path="/search/:query"
                 render={({ match }) => <SearchPage query={match.params.query}
                                            searchKey={this.state.searchKey}
                                            self={this.state.self} />}/>
          <Route path="/search/"
                  render={() => <SearchPage query=''
                                  searchKey={this.state.searchKey}
                                  self={this.state.self} />}/>                                 
          <Route 
            path="/" 
            exact 
            render={() => 
              <Homepage 
                search={this.onSearch}
                self={this.state.self}
              />
            } />

          <Route path="/teams"
                 exact
                 render={() => <TeamsPage path="/teams"
                                          key="teams"
                                          headerImg="/img/mascot/User-Profile-Pose.png"
                                          isTeam={true}
                                          disabledFields={disabledFields}
                                          isCompanyAdmin={isCompanyAdmin} />} />
          <Route path="/groups"
                 exact
                 render={() => <TeamsPage path="/groups"
                                          headerImg="/img/mascot/Skiing-Pose.png"
                                          key="groups"
                                          isTeam={false}
                                          disabledFields={disabledFields}
                                          isCompanyAdmin={isCompanyAdmin} />} />
          <Route path="/all"
                exact
                render={() => <AllPage />} />
          <Route path="/people/:people_id" exact render={
            ({ match }) => (
              <ProfilePage key={match.params.people_id}
                           self={this.state.self}
                           person_id={match.params.people_id} />
            )
          } />
          <Route path="/people/:people_id/edit" exact render={
            ({ match }) => (
              <OnboardingPage person_id={match.params.people_id} admin={isCompanyAdmin} disabledFields={disabledFields} searchKey={this.state.searchKey} self={this.state.self} />
            )
          } />
          <Route path="/objectives/:people_id/edit" exact render={
            ({ match }) => (
              <OkrsEditPage person_id={match.params.people_id} />
            )
          } />
          <Route path="/team/:team_id"
                 exact
                 render={({ match }) => (
                   <TeamPage key={match.params.team_id}
                             team_id={match.params.team_id}
                             self={this.state.self} />
                 )} />
          <Route path="/team/:team_id/edit" exact render={
            ({ match }) => (
              <TeamEditPage team_id={match.params.team_id} searchKey={this.state.searchKey} admin={isCompanyAdmin} />
            )
          } />
          <Route path="/create-post"
                   exact
                   render={() => <PostEditPage self={this.state.self} admin={isCompanyAdmin} />} />
          <Route path="/post/:post_id/edit"
                   exact
                   render={({ match }) => <PostEditPage self={this.state.self} post_id={match.params.post_id} admin={isCompanyAdmin} />} />
          <Route path="/post/:post_id"
                   exact
                   render={({ match }) => <PostPage history={this.props.history} post_id={match.params.post_id} self={this.state.self} admin={isCompanyAdmin} />} />
          {
            (isCompanyAdmin || disabledFields.indexOf('team') <= -1) &&
            <Route path="/create-team"
                   exact
                   render={() => <TeamEditPage searchKey={this.state.searchKey} isTeam={true}/>} />
          }
          
            <Route path="/create-group"
                   exact
                   render={() => <TeamEditPage searchKey={this.state.searchKey} isTeam={false}/>} />
          
          {
            isCompanyAdmin &&
            <Route path="/admin/:tab"
                   exact
                   render={({ match }) => (
                     <AdminPage self={this.state.self}
                                tab={match.params.tab}
                                searchKey={this.state.searchKey}
                                onSettingsChange={() => this.refreshData()}/>
                   )}/>
          }
          {
            isCompanyAdmin &&
            <Route path="/admin"
                   exact
                   render={() => <AdminPage self={this.state.self}
                                            tab="settings"
                                            searchKey={this.state.searchKey}
                                            onSettingsChange={() => this.refreshData()}/>
                   }/>
          }
          {
            isCompanyAdmin &&
            <Route path="/announcement/:announcement_id/edit"
                   exact
                   render={({ match }) => (
                     <AnnouncementEditPage announcement_id={match.params.announcement_id} />
                   )} />
          }
          {
            isCompanyAdmin &&
            <Route path="/create-announcement"
                   exact
                   render={() => <AnnouncementEditPage />} />
          }

          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
