import React, { Component } from 'react';
import { InstantSearch, Hits, Configure, connectSearchBox} from 'react-instantsearch-dom';
import { TextInputField } from 'evergreen-ui';
import '../styles/people_select.scss';
import algoliasearch from 'algoliasearch';

class PeopleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPerson: null,
      query: '',
    };

    this.profileResult = this.profileResult.bind(this);
  }

  componentDidMount() {
    this.setState({selectedPerson: this.props.initialPerson});
  }

  selectedPerson(person) {
    this.props.onSelectPerson(person);
    this.setState({selectedPerson: person});
  }

  profileResult({hit}) {
    return (
      <div className="profile-result" onClick={() => this.selectedPerson(hit)}>
          <div><span>{hit.first_name} {hit.last_name}</span></div>
      </div>
    );
  }

  render() {
    if (!this.props.searchKey) return null;

    const indexSuffix =  (window.location.host.includes("localhost") || window.location.host.includes("staging")) ? "dev" : "prod";
    
    const MySearch = connectSearchBox(({value, refine}) => {return(<TextInputField 
      label={this.props.label} 
      className="search-box"
      value={value} 
      placeholder={this.state.selectedPerson ? this.state.selectedPerson.first_name + " " + this.state.selectedPerson.last_name : "None"}
      onChange={event => {refine(event.target.value);}}
   />)});

    const algoliaClient = algoliasearch('XS946XNLUJ', this.props.searchKey, {
      _useRequestCache: true,
    });

    const searchClient = {
      search(requests) {
        const shouldSearch = requests[0].params.query !== '';
        if (shouldSearch) {
          return algoliaClient.search(requests);
        }
        return Promise.resolve({
          results: [{ hits: [] }],
        });
      },
      searchForFacetValues: algoliaClient.searchForFacetValues,
    };

    const active_prop = this.props.active ? this.props.active : "true";

    return (
      <div style={this.props.style} className="people-selector">
        <InstantSearch
          searchClient={searchClient}
          indexName={"person_index_" + indexSuffix}>
          <div className="d-flex flex-row">
            <div style={{width: '100%'}}>
              <MySearch />
            </div>
            {this.props.clear && 
              <i onClick={() => this.selectedPerson({first_name: 'None', last_name: '', objectId: null})} className="fas fa-times-circle clear-button"></i>
            }
          </div>

          <Configure hitsPerPage={4} filters={"active:" + active_prop} />
          <Hits hitComponent={this.profileResult} />
        </InstantSearch>
      </div>
     
    );
  }
}

export default PeopleSelector;