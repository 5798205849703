import React, { Component } from 'react';
import '../styles/post.scss';
import ReactMarkdown from 'react-markdown';
import * as moment from 'moment';
import {Link} from 'react-router-dom';
import PostCommentWidget from './PostCommentWidget.react.js';

class CommentWidget extends Component {

  MAX_DEPTH = 4;

  constructor(props) {
    super(props);

    this.state = {
      reply: false,
      edit: false
    }
  }

  render() {
    const comment = this.props.comment;
    const posted = moment(comment.posted).fromNow();

    var reply = <div onClick={() => this.setState({reply: true})} className="pr-2 post-clickable">Reply</div>;
    var content = <div style={{marginBottom: '-0.5rem'}}><ReactMarkdown className="post-content" source={comment.content} /></div>;
    const can_edit = comment.author.id === this.props.person_id || this.props.admin;
    const edit_button = (!this.state.edit && can_edit) ? <div className="pr-2 post-clickable" onClick={() => this.setState({edit: true})}> Edit </div> : null;
    const delete_button = can_edit ? <div className="pr-2 post-clickable" onClick={() => this.props.onDelete(comment.id)}> Delete </div> : null;

    if (this.state.reply) {
      reply = <PostCommentWidget post_id={comment.id} onClose={() => this.setState({reply: false})} onSubmit={() => {this.setState({reply: false}); this.props.refreshData();} } />
    } 
    
    if (this.state.edit) {
      content = <PostCommentWidget comment_id={comment.id} onClose={() => this.setState({edit: false})} onSubmit={() => {this.setState({edit: false}); this.props.refreshData();}} content={comment.content} />
    }
    
    if (this.props.depth === this.MAX_DEPTH) {
      reply = null;
    }

    return (
        <div className="d-flex flex-row mx-auto mt-3" style={{maxWidth: 992, width: '100%'}}>

          <div className="d-flex flex-column align-items-start" style={{width: '100%'}}>
            <div className="comment-header d-flex flex-row">
              <Link className="post-author-link router-link" to={"/people/" + comment.author.email} >
               <img src={comment.author.photo} width={20} height={20} style={{borderRadius: 50}} alt="comment-author" className="mr-2"/>
               {comment.author.first_name} {comment.author.last_name}
              </Link> <div style={{color: '#000000ab', marginLeft: 10, fontWeight: 200}}>{posted}</div>
            </div>
            {content}
            <div className="d-flex flex-row flex-wrap" style={{ width: '100%'}}>
              {edit_button} {delete_button} {reply} 
            </div>
            
            { this.props.depth <= this.MAX_DEPTH &&
              comment.ordered_children.map((child, index) => 
              {
                const _key = this.props._key + `_lvl_${index}`;
                return (<CommentWidget 
                  depth={this.props.depth + 1}
                  comment={child} 
                  refreshData={this.props.refreshData} 
                  person_id={this.props.person_id} 
                  admin={this.props.admin} 
                  onDelete={this.props.onDelete}  
                  _key={_key}
                  key={_key} />);
              })
            }
          </div>
          
          
        </div>
    );
  }
}

export default CommentWidget;