import React, { Component } from 'react';
import PeopleGroupView from '../shared/components/PeopleGroupView.react.js';
import PageTopContent from '../shared/components/PageTopContent.react';
import { analyticsPage } from '../../utils.js';

class AllPage extends Component {
  componentDidMount() {
    analyticsPage("people_list");
  }

  render() {
    return (
      <div>
        <PageTopContent title="Everyone" >
        <img src="/img/mascot/Celebratory-Pose.png" alt="mascot" className="header-image" />

        </PageTopContent>

        <div className="d-flex align-items-center mt-3 mt-md-4">
          <div className="background-bottom">
            <PeopleGroupView path="/people" />
          </div>
        </div>
      </div>
    );
  }
}

export default AllPage;
