import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import '../styles/profile.scss';
import { getDate, analyticsTrack, isDemo } from '../../../utils.js';
import PeopleSmallCard from '../../shared/components/PeopleSmallCard.react.js';

class ProfileTabView extends Component {
  constructor(props) {
    super(props);

    this.counter = 0;
    this.okrStatusColor = {
      '0': 'transparent',
      '1': '#77f7ad',
      '2': '#fdff84',
      '3': '#ff8888',
    };

    this.state = {
      selected_tab: "info",
      longitude: 0,
      latitude: 0,
      fullLocation: '',
      expandedOkr: '',
    };
    
    this.groupDisplay = this.groupDisplay.bind(this);

    this.infoTab = this.infoTab.bind(this);
    this.socialTab = this.socialTab.bind(this);

    this.divider = (key) => 
      <div key={key} style={{ width: '100%', height: 1, marginTop: -10, backgroundColor: "rgba(255, 255, 255, 0.03)" }} />;
  }

  generateRow(labelComponent, content) {
    if (!content) {
      return null;
    }
    let content_component = <span className="profile-tab-right-content">{content}</span>;

    this.counter += 1;

    return (
      <div key={this.counter} className="profile-tab-content-row">
        {labelComponent}
        {content_component}
      </div>
    );
  }

  groupDisplay() {
    let show_more = <div key="show_more" />;
    let person_groups = this.props.person.groups;
    let groupComponents = person_groups ? person_groups.map((group) =>
      <div className="profile-group-bubble grow" key={"profile-group-bubble" + group.id}>
        <Link to={"/team/" + group.id}>{group.name}</Link>
      </div>
    ) : [];
    groupComponents.push(show_more);

    return (
      <div key="groups" className="profile-tab-content-row">
        <p>Groups</p>
        <div className="profile-group-bubbles-container">
          {groupComponents}
        </div>
      </div>
    );
  }

  infoTab() {
    let extraFieldComponents = this.props.person.extra_fields.map((field) => this.generateRow(<p>{field.label}</p>, field.value));
    var manager_card = this.props.person.manager ? <PeopleSmallCard person={this.props.person.manager} /> : null;
    var team = null;
    if (this.props.person.team) {
      team = <div className="profile-group-bubble grow" style={{marginTop: 0}} key={"profile-group-bubble" + this.props.person.team.id}>
      <Link to={"/team/" + this.props.person.team.id}>{this.props.person.team.name}</Link>
    </div>;
            
    }
    return [
      this.generateRow(<p>Team</p>, team),
      this.generateRow(<p>Manager</p>, this.props.self.company.manager ? manager_card : null),
      this.generateRow(<p>Joined On</p>, this.props.self.company.joined && this.props.person.joined ? getDate(this.props.person.joined).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : null),
      this.generateRow(<p>Birthday</p>, this.props.self.company.birthday && this.props.person.birthday ? getDate(this.props.person.birthday).toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) : null),
    ].concat(extraFieldComponents);
  }

  generateSocialLink(base_url, content, new_tab) {
    if (!content) {
      return null;
    }
    return (
      <a 
        onClick={() => analyticsTrack('profile_socialLinkClicked', {path: base_url, content: content, person: this.props.person.email})}
        href={base_url + content}
        target={new_tab ? "_blank" : ""}
        rel={new_tab ? "noopener noreferrer" : ""}>
        {content}
      </a>
    );
  }

  socialTab() {
    var linkedin = this.props.person.linkedin;
    if (linkedin) {
      linkedin = this.props.person.linkedin && this.props.person.linkedin.includes("/") ? this.props.person.linkedin : 'in/' + this.props.person.linkedin;
    }
    return [
      this.generateRow(<i className="fab fa-github profile-tab-icon" />, this.generateSocialLink("https://github.com/", this.props.self.company.github && this.props.person.github, true)),
      this.generateRow(<i className="fab fa-twitter profile-tab-icon" />, this.generateSocialLink("https://twitter.com/", this.props.person.twitter, true)),
      this.generateRow(<i className="fab fa-linkedin profile-tab-icon" />, this.generateSocialLink("http://linkedin.com/", linkedin, true)),
      this.generateRow(<i className="fas fa-envelope profile-tab-icon" />, this.generateSocialLink("mailto:", this.props.person.ah_email, false)),
      this.generateRow(<i className="fas fa-phone profile-tab-icon" />, this.generateSocialLink("tel:", this.props.person.ah_phone, false)),
      this.groupDisplay(),

    ]
  }

  
  renderHeader() {
    return (
      <div className="profile-tab-header-container ma fw fo">
        <div className="bio-text-container d-flex flex-column">
          {
            !this.props.person.active &&
            <div className="profile-inactive">
              This profile is inactive!
            </div>
          }
          <h5>
            <span className="white-text blt">{this.props.person.first_name} {this.props.person.last_name}</span>
            {
              this.props.person.editable && this.props.person.active && !isDemo() &&
              <Link to={"/people/" + this.props.person.email + "/edit"}>
                <img className="team-edit-icon ml-2"
                     alt="edit"
                     src="/img/edit.png"/>
              </Link>
            }

          </h5>

          <div className="bio-role-team-row d-flex flex-wrap flex-row align-items-center my-1">
            <div className="white-text blt">
              {this.props.person.role}
            </div>
          </div>
          {
            this.props.person.bio &&
            <p className="white-text blt pl-1 mt-3" style={{fontSize: 12}}>{this.props.person.bio}</p>
          }
        </div>

        <div className="bio-contact">
          <div className="d-flex flex-row flex-wrap" style={{marginTop: -10}}>
            <div style={{marginRight: 20, marginTop: 10}}>
              {this.props.person.company.slack_id && this.props.person.slack &&
              <a href={"slack://user?team=" + this.props.person.company.slack_id + "&id=" + this.props.person.slack_id}
                 className="d-flex">
                <i className="fab fa-slack bio-contact-icon white-text blt"/>
                <span className="white-text blt">{this.props.person.slack}</span>
              </a>
              }
            </div>
            <div style={{marginTop: 10}}>
              <a href={"mailto:" + this.props.person.email} className="d-flex">
                <i className="fas fa-envelope bio-contact-icon white-text blt"/>
                <span className="white-text blt">{this.props.person.email}</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    );
  }

  managementChainDisplay() {
    const generatePointer = (person, className, index) => {
      let role = person.role && person.role.length > 27 ? person.role.substr(0, 24) + '...' : person.role;
      return (
        <Link
          onClick={() => analyticsTrack('profile_managementChainClicked', {person: this.props.person.email, clicked_person: person.email})}
          key={`${index}PointerKey`}
          className={"pointer grow grow-small " + className}
          to={"/people/" + person.email}
        >
          <span>{person.first_name} {person.last_name}</span>
          <p>{role}</p>
        </Link>
      );
    };

    let managementCards = this.props.person.management_chain.map((person, index) =>
      generatePointer(person, "pointer-previous " + (index !== this.props.person.management_chain.length - 1 ? "pointer-next" : ""), index)
    );

    if (!this.props.self.company.manager) {
      return null;
    }

    return (
      <div className="d-flex flex-column justify-content-start management-chain ma fw">
        <h5 className="new-hires-header mt-3 text-left ma p-header p-margin" style={{marginLeft: 22}}>Reporting Chain</h5>
        <div className="d-flex flex-row flex-wrap p-margin">
          <div className="pointer pointer-next current-pointer">
            <span>{this.props.person.first_name} {this.props.person.last_name}</span>
            <p>{this.props.person.role}</p>
          </div>
          {managementCards}
        </div>
      </div>
    );
  }

  render() {
    const reports = this.props.self.company.manager && this.props.reports.length ? this.props.reports.map((report, idx) => <div key={`report${idx}`} style={{paddingRight: 10}}><PeopleSmallCard person={report} />
      </div>) : null;

    return (
      <div className="profile-tab-container ml-lg-4 mx-xl-4 mb-4 mb-xl-0">
        {this.renderHeader()}
        <div className="d-flex flex-row">

        </div>
        <h5 className="new-hires-header mt-3 text-left ma p-header p-margin">About</h5>
        <div className="profile-tab-content ma " style={{maxWidth: 400}}>
          {this.infoTab()}
          {this.socialTab()}
        </div>
        {this.props.person.management_chain.length ? this.managementChainDisplay() : null}
        {reports && <h5 className="new-hires-header my-3 text-left ma p-header p-margin">Reports</h5>}
        <div className="container-fluid">
            <div className="row ma fw " style={{marginLeft: 10}}>
              {reports}
            </div>
          </div>

      </div>
    );
  }
}

export default withRouter(ProfileTabView);
