import React, { Component } from 'react';
import { Card, TextInputField, Button } from 'evergreen-ui';
import ApiClient from '../../ApiClient';
import { analyticsPage } from '../../utils';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
  }

  componentDidMount() {
    analyticsPage("login");
  }

  onSubmit() {
    ApiClient.no_auth_get('/email_login_redirect/' + this.state.email).then((data) => {
      if (data.redirect) {
        window.location.replace(data.redirect);
      } else {
        this.setState({error: true})
      }
    }).catch(console.error);
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onSubmit();
    }
  }

  render() {
    var error = null;
    if (this.state.error) {
      error = <p style={{color: 'red'}}>Couldn't find that user. Please try again!</p>;

    }
    return (
      <div className="d-flex flex-column justify-content-center align-items-center"
           style={{
             position: 'fixed',
             padding: 0,
             margin: 0,
             top: 0,
             left: 0,
             width: '100%',
             height: '100%',
             backgroundColor: '#202c4b',
           }}>
        <Card flex={1}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              display="flex"
              width="100%"
              elevation={0}
              backgroundColor="white"
              style={{
                textAlign: 'left',
                maxWidth: '400px',
                maxHeight: '360px',
                fontSize: '14px',
                color: '#212a34',
              }}>
          <span className="m-3">Welcome back!</span>
          {error}
          <Card flex={1}
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                display="flex"
                width="100%"
                elevation={0}
                backgroundColor="#ebecf0">
            <TextInputField
              onKeyPress={this._handleKeyPress}
              width="90%"
              label="Email"
              onChange={(e) => {this.setState({email: e.target.value})}}
              style={{
                height: '40px'
              }}
            />
          </Card>
          <Card alignItems="center"
                justifyContent="center"
                display="flex"
                width="100%"
                elevation={0}>
            <Button onClick={() => this.onSubmit()}
                    appearance="primary" width="100%"
                    margin={16}
                    backgroundColor="#1ca1ff"
                    display="flex"
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      backgroundColor: '#1ca1ff',
                      backgroundImage: 'none',
                      padding: '20px',
                      fontSize: '13px',
                      lineHeight: 0,
                    }}>Log In</Button>
          </Card>
        </Card>
        <a href="https://demo.ontrestle.com" style={{marginTop: 20, color: 'white'}}>Looking for the demo?</a>
      </div>
    );
  }
}



export default LoginPage;
