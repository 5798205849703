import React, { Component } from 'react';
import ApiClient from '../../ApiClient.js';
import './styles/bio.css';
import ProfileTabView from './components/ProfileTabView.react.js';
import PageLoader from '../shared/components/PageLoader.react';
import { analyticsPage } from '../../utils.js';
let ts = require('@mapbox/timespace');

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: null,
      reports: []
    };

    this.updatePerson = this.updatePerson.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  updatePerson(data) {
    let fullDict = {
      ...this.state.person,
      ...data,
    };
    this.setState({ person: fullDict });
  }

  componentDidMount() {
    this.refreshData();
    analyticsPage("profile_view");
  }

  /**
   * Retrieves the person's data.
   */
  refreshData() {
    ApiClient.get("/people/" + this.props.person_id).then(
      data => { this.setState({ person: data }); }
    ); 

    ApiClient.get("/people/" + this.props.person_id + "/reports").then(
      data => { this.setState({ reports: data }); }
    ); 
  }

  mapDisplay() {
    if (!this.state.person.longitude) return null;

    const TextBubble = props => {
      return (
        <div className="profile-map-text-bubble d-flex align-items-center">
          {props.children}
        </div>
      );
    };

    let map_img_src = "https://maps.googleapis.com/maps/api/staticmap?center=" + this.state.person.latitude + "," + this.state.person.longitude
      + "&zoom=11&size=622x225&maptype=roadmap&style=feature:all|element:labels|visibility:off&style=feature:road.arterial%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&key=AIzaSyD6WUh2VAelobJzo0hy2YiMHU_W2dP9tfY"
    let person_time = ts.getFuzzyLocalTimeFromPoint(Date.now(), [this.state.person.longitude, this.state.person.latitude]).format('h:mm A');

    return (
      <div className="profile-tab-map-container" style={{marginTop: 20}}>
        <img src={map_img_src} alt="map" />
        <div className="d-flex flex-row justify-content-between">
          <TextBubble>
            {this.state.person.location}
          </TextBubble>

          <TextBubble style={{ marginLeft: "auto" }}>
            {person_time}
          </TextBubble>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.person) return (
      <PageLoader />
    );


    return (
      <div>
        <div className="pb-xl-5">
          <div className="profile-background-top"></div>
          <div className="profile-content-container mx-auto d-flex justify-content-center align-items-center align-items-lg-start flex-column flex-lg-row" style={{marginTop: -250}}>

            <div className="bio-container d-flex flex-column mb-4 mb-xl-0" >
              <div className="bio-image-container d-flex align-items-center justify-content-center">
                <img src={this.state.person.photo} alt="profile" />
              </div>
              {this.mapDisplay()}
            </div>

            <ProfileTabView person={this.state.person}
                            self={this.props.self}
                            reports={this.state.reports}
                            updatePerson={this.updatePerson}
                            refresh={() => this.refreshData()} />

          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;