import React, { Component } from 'react';

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <h2 className="mt-3">404 Not found</h2>
        <img src="/img/mascot/Binocular-Pose.png" style={{height: 400}} alt="mascot"/>
      </div>
    );
  }
}

export default NotFoundPage;
