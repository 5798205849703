/**
 * Checks if the valus is a valid link.
 *
 * @param value
 * @returns {boolean}
 */
export const isValidLink = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  return value.match(/^(?:http(s)?:\/\/)?(?:ftp(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/);
};

/**
 * Validates string length.
 *
 * Returns false if any invalid values are found.
 *
 * @param {Array<any>} values Values containing objects
 * @param {string} prop Name of the property to check
 * @param {number} minLength Minimum length of the string
 * @returns {*}
 */
export const isValidString = (values = [], prop, minLength) => {
  return !values.find(val => (!val[prop] || val[prop].length < minLength));
};

/**
 * Validates resource links.
 *
 * Checks if the values are valid links.
 * @returns {*}
 */
export const resourceLinksNotValid = resources => {
  return resources.find(({ link }) => !isValidLink(link));
};

/**
 * Parse resource links.
 *
 * Checks if the links contain '://' and if not appends 'http://' to the link.
 * @returns {*}
 */
export const parseResourceLinks = resources => {
  return resources.map(resource => {
      if (resource.link && !String(resource.link).includes('://')) {
        resource.link = 'http://' + resource.link;
      }

      return resource;
    });
};

/**
 * Validates titles links.
 *
 * Checks if the values are valid links.
 * @returns {*}
 */
export const resourceTitlesNotValid = (resources, minLength) => {
  return !isValidString(resources, 'title', minLength);
};

/**
 * Validates if the passed e-mail is valid.
 *
 * @param email
 * @returns {boolean}
 */
export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Capitalizes the first letter of the word.
 *
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Removes a trailing forward slash from text if it exists.
 *
 * @param {string} text
 * @returns {string} Parsed text
 */
export const removeTrailingSlash = text => {
  if (text[text.length - 1] === '/') {
    return text.slice(0, text.length - 1);
  }

  return text;
};
