import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ApiClient from '../../../ApiClient.js';
import * as moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { toaster } from 'evergreen-ui';
import Loader from 'react-loader-spinner';

import '../styles/announcements.scss';
import Button from '../../shared/components/Button.react';
import Title from '../../shared/components/Title.react';

import { isDemo } from '../../../utils';

class AnnouncementsView extends Component {
  errors = {
    unexpected: 'An error occurred',
  };

  constructor(props) {
    super(props);
    this.state = {
      announcements: null,
      has_next: false,
      has_previous: false,
      cursor: 1,
    }
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * Retrieves posts data.
   *
   * @param page_id
   */
  getData(page_id = 1) {

    ApiClient.get(`/posts?page=${page_id}`)
    .then(
      data => {
        this.setState({ announcements: [], has_next: data.has_next, has_previous: data.has_previous, cursor: parseInt(data.cursor)});

        data.posts.forEach(({ id }) => {
          ApiClient.get(`/posts/${id}`)
            .then(post => {
              console.log(post)
              const { announcements } = this.state;
              announcements.push(post);
              this.setState({ announcements })
            })
            .catch(console.error);
        })
      }
    )
    .catch(err => this.onError(err))
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
  }

  renderAnnouncement({ title, content, posted, pinned, announcement, num_comments, id, author, views }, index) {
    const formattedDate = moment(posted).fromNow();
    let announceTag = null;
    let pinnedTag = null;

    if (pinned) {
      pinnedTag =
        <div className={`post-preview-tag post-preview-pinned-tag d-flex align-items-center justify-content-center px-2`}>
          <i className="fas fa-thumbtack"></i>
          <span>&nbsp;PINNED POST</span>
        </div>
    }
    if (announcement) {
      announceTag =
      <div className={`post-preview-tag post-preview-announcement-tag d-flex align-items-center justify-content-center px-3 ${pinned ? 'ml-sm-2 mt-1 mt-sm-0' : ''}`}>
        <i className="fas fa-star"></i>
        <span>&nbsp;ANNOUNCEMENT</span>
      </div>
    }

    const Circle = <i className="fas fa-circle px-2 light-gray-text" style={{fontSize: 4}}></i>;

    content = content.length > 500 ? content.substr(0, 500) + '...' : content;

    return (
      <div key={`AnnouncementRowKey${index}`}
           className="p-2 my-4 announcements-row d-flex align-items-center">
        <div className="d-flex flex-row align-items-center">
          <div className="col-12">
            <div className="ml-2">

              <div className="d-flex flex-row align-items-center mb-1 mt-2">
                <div className="d-flex flex-column flex-sm-row">
                  {pinnedTag}
                  {announceTag}
                </div>
                <div>
                  <div className="d-flex flex-row align-items-center">
                    {
                      (pinnedTag || announceTag) &&
                      <div style={{marginTop: '-5px'}}>
                        {Circle}
                      </div>
                    }

                    <div className="pr-1 light-gray-text" style={{ marginTop: '1px' }}>
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </div>

              <Link to={"/post/" + id} className="d-flex flex-column rl-none router-link post-title" style={{width: 'fit-content'}}>
                <p className="announcement-preview-title">{title}</p>

                <ReactMarkdown source={content} />

              </Link>

              <Link className="rl-standard d-flex flex-row align-items-center" to={"/people/" + author.email}>

                <div className="d-flex flex-row justify-content-center align-items-center my-1">
                  <img src={author.photo} className="events-photo-icon" alt="profile" />

                  <div className="event-detail-content d-flex px-3 flex-column">
                        <span style={{ lineHeight: '16px' }}>
                          <div className="d-flex align-items-center light-gray-text">{author.first_name} {author.last_name}</div>
                        </span>
                    <div className="small-text light-gray-text" style={{ marginTop: '3px' }}>{author.role}</div>
                  </div>
                </div>
              </Link>

              <div className="d-flex flex-row align-items-center mt-2">
                <Link to={"/post/" + id} className="rl-standard">
                  <span className="light-gray-text">Comments: {num_comments}</span>
                </Link>

                {Circle}

                <div className="light-gray-text">
                  <span className="light-gray-text">Views: {views}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { announcements } = this.state;

    if (announcements === null) {
      return (
        <div className={" col-12 col-lg-7" + this.props.col}>
          <div className="mt-3">
            <Loader className="col-12" type="Oval" color="grey"/>
          </div>
        </div>
      );
    }

    return (
      <div className={"col-12 col-lg-" + this.props.col}>
        <div className="row">
          

          <div className="col-12 ">
            <div className="announcements-container mt-4 mx-auto">
              <div className="announcements-header d-flex justify-content-between align-items-center px-4">
                <Title>Posts</Title>
                {
                  !isDemo() &&
                  <Link to="/create-post" className="router-link rl-none">
                    <Button type="primary" className="px-4">New Post</Button>
                  </Link>
                }
              </div>
              <div className="text-left">
                {
                  announcements.map((announcement, index) => this.renderAnnouncement(announcement, index))
                }
              </div>

              <div className="d-flex flex-row">
                <div className="d-flex align-items-center justify-content-end" style={{width: '100%'}}>
                  {this.state.has_previous && <div className="clickable post-title" style={{width: 100}} onClick={() => this.getData(this.state.cursor - 1)}>◀ Previous</div>}
                  {this.state.has_next && <div className="clickable post-title" style={{width: 100}} onClick={() => this.getData(this.state.cursor + 1)}>Next ▶</div>}
                </div>
              </div>
            </div>
            <div onClick={() => {
              if (window.Intercom) {
                window.Intercom("show");
              } else {
                window.open("https://goo.gl/forms/HNR8IgYpxh3gnbAD3", "_blank");
              }
            }} className="d-none d-lg-flex align-items-center clickable justify-content-center light-gray-border feedback-footer">Have any feedback on Trestle? We'd love to hear it!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnnouncementsView;
