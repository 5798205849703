import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, Label, Textarea, toaster } from 'evergreen-ui';
import Loader from 'react-loader-spinner';

import './styles/team-edit.scss';
import ApiClient from '../../ApiClient.js';

// shared components
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';
import EditResources from '../shared/components/resources/EditResources.react';
import Section from '../shared/components/Section.react';
import DeactivateModal from '../shared/components/DeactivateModal.react';

// utils
import {
  resourceLinksNotValid,
  resourceTitlesNotValid,
  isValidEmail,
  parseResourceLinks
} from '../shared/utils';
import PeopleSelector from '../shared/components/PeopleSelector.react';
import { analyticsPage } from '../../utils';

class TeamEditPage extends Component {
  state = {
    team: null,
    submitClicked: false,
    loading: false,
  };

  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    name: 'Name is not valid',
    resourceTitles: 'Resource titles are not valid',
    resourceLinks: 'Resource links are not valid',
    email: 'Email is not valid',
  };

  /**
   * Validation values.
   */
  validations = {
    maxCount: {
      resource: 4,
    },
    minTitleLength: 1,
  };

  /**
   * Retrieves the team and self objects.
   */
  constructor(props) {
    super(props);

    const promises = [];

    const $self = ApiClient.get('/self')
      .catch(err => this.onError(err));

    promises.push($self);

    if (!this.isCreating()) {
      const $team = ApiClient.get(`/groups/${this.props.team_id}`)
        .catch(err => this.onError(err));

      const $resources = ApiClient.get(`/groups/${this.props.team_id}/resources`)
        .catch(err => this.onError(err));

      promises.push($team, $resources)
    }

    Promise.all(promises)
      .then(data => this.onTeamRetrieved(data))
  }

  componentDidMount() {
    analyticsPage("team_edit");
  }

  /**
   * Determines if the team / group is being created or edited.
   * @returns {boolean}
   */
  isCreating() {
    return typeof this.props.team_id === 'undefined';
  }

  /**
   * Updates the team and whether the current user is the team's point of contact.
   *
   * If a new team/group is being created, calls the function to create one.
   */
  onTeamRetrieved([self, team = this.createNewTeam(), resources = []]) {
    this.setState({
      self, team, resources
    });
  }

  /**
   * Adds an is_team property to the new team based on the property that is passed in the route.
   *
   * @returns {{is_team: boolean}}
   */
  createNewTeam() {
    return {
      is_team: this.props.isTeam
    }
  }

  /**
   * Submits the team/groups's info.
   *
   * Sets the submitClicked flat to true.
   */
  onSubmit() {
    let { resources } = this.state;
    const { team, loading } = this.state;

    if (loading) {
      return;
    }

    var {
      name,
      description, contact_email, contact_slack, point_of_contact, is_team,
    } = team;

    if (point_of_contact && typeof point_of_contact === 'object') {
      point_of_contact = point_of_contact.id;
    }
    const payload = {
      description, contact_email, contact_slack, point_of_contact,
    };

    this.setState({ submitClicked: true });

    if (resourceTitlesNotValid(resources, this.validations.minTitleLength)) {
      return toaster.danger(this.errors.resourceTitles);
    }

    if (resourceLinksNotValid(resources)) {
      return toaster.danger(this.errors.resourceLinks);
    }

    if (contact_email && !isValidEmail(contact_email)) {
      return toaster.danger(this.errors.email);
    }

    // parse resource links
    resources = parseResourceLinks(resources);

    if (this.isCreating()) {
      // for creating a team/group the name is required
      if (!name || name.length < this.validations.minTitleLength) {
        return toaster.danger(this.errors.name)
      }

      this.setState({loading: true});

      ApiClient.post('/groups', {
        ...payload,
        name,
        is_team,
      }).then(({ id }) => {
        const callback = () => this.onTeamUpdated({ id });

        // if there are resources added, add them separately
        if (resources && resources.length > 0) {
          return ApiClient.post(`/groups/${id}/resources`, resources)
            .then(callback)
        } else {
          callback();
        }
      })
        .catch(err => this.onError(err));
    } else {
      if (this.props.admin && (!name || name.length < this.validations.minTitleLength)) {
        return toaster.danger(this.errors.name)
      } 
      var true_payload = payload;
      if (this.props.admin) {
        true_payload = {...payload, name};
      } 
      this.setState({loading: true});
      const $resources = ApiClient.post(`/groups/${this.props.team_id}/resources`, resources);
      const $data = ApiClient.post(`/groups/${this.props.team_id}`, true_payload);

      Promise.all([$resources, $data])
        .then(() => this.onTeamUpdated({id: this.props.team_id}))
        .catch(err => this.onError(err));
    }
  }

  /**
   * Team updated event handler.
   *
   * Navigates to the team page.
   */
  onTeamUpdated({ id }) {
    this.setState( { loading: false });
    this.props.history.push(`/team/${id}`);
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { loading: false });
  }

  /**
   * Form property changes handler.
   *
   * @param {string} propName Property name
   * @param {*} propValue Property value
   */
  onPropChanged(propName, propValue) {
    const { team } = this.state;
    team[propName] = propValue;

    this.setState({ team })
  }


  /**
   * Renders the top content.
   *
   * If a new team/group is being created then adjusts the title accordingly.
   */
  renderTopContent() {
    const { team, loading } = this.state;
    const { name, is_team } = team;
    const descriptor = is_team ? 'Team' : 'Group';
    let title;

    if (this.isCreating()) {
      title = `Create ${descriptor}`;
    } else {
      title = `Edit ${descriptor}: ${name}`;
    }

    return (
      <PageTopContent
        title={title}>
        <div className="ml-auto mt-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40} />
            }
          </Button>
        </div>
      </PageTopContent>
    );
  }

  /**
   * Renders the fields used in the general section.
   *
   * Only renders the name text field if a new team/group is being created.
   */
  renderGeneralSection() {
    const { team } = this.state;
    const {
      is_team, name,
      point_of_contact,
      description, contact_email, contact_slack
    } = team;

    const descriptor = is_team ? "Team" : "Group";

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInputField
              disabled={!this.isCreating() && !this.props.admin}
              label="Name"
              defaultValue={name}
              placeholder={`${descriptor} name`}
              onChange={event => this.onPropChanged('name', event.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Label
              htmlFor="description"
              marginBottom={4}
              display="block"
            >
              Description
            </Label>

            <Textarea
              id="description"
              style={{marginBottom: 24}}
              placeholder={descriptor + " description..."}
              defaultValue={description}
              maxLength={160}
              onChange={event => this.onPropChanged('description', event.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInputField
              isInvalid={(contact_email && !isValidEmail(contact_email))}
              label="Email"
              defaultValue={contact_email}
              placeholder={`${descriptor} email`}
              onChange={event => this.onPropChanged('contact_email', event.target.value)}
            />
          </div>

          <div className="col-12 col-md-6">
            <TextInputField
              label="Slack Channel"
              defaultValue={contact_slack}
              placeholder={`${descriptor} slack channel`}
              onChange={event => this.onPropChanged('contact_slack', event.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <PeopleSelector 
              label="Point of Contact" 
              searchKey={this.props.searchKey} 
              clear={true}
              initialPerson={point_of_contact} 
              onSelectPerson={(person) => this.onPropChanged('point_of_contact', person.objectID ? parseInt(person.objectID) : null)} 
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { loading } = this.state;

    const deactivateGroupModal = 
      <DeactivateModal 
        title="Deactivate Group" 
        submitUrl={"/groups/" + this.props.team_id + "/active"}
        prompt={"Are you sure you want to deactivate " + this.state.team.name + "? This group will no longer show up in Trestle."}
        ref="deactivate_group_modal" 
        submitSuccess={() => this.props.history.push('/')}
      />;

    return (
      <div className="text-left">
        <Section title="General">
          {this.renderGeneralSection()}
        </Section>

        <EditResources resources={this.state.resources}
                       smaller={true}
                       updateResources={resources => this.updateResources(resources)}
                       submitClicked={this.state.submitClicked}/>

        <div className="full-width-item mx-auto d-flex">
          {
            this.props.admin &&
            <Button 
              onClick={() => this.refs.deactivate_group_modal.showModal()}
              className="mr-auto my-4 ml-3"
              style={{
                backgroundColor: '#fb3434',
                width: '100px'
              }}
              appearance="primary">
              Deactivate 
             </Button>
            
          }
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto my-4 mr-3"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40} />
            }
          </Button>
        </div>
        {deactivateGroupModal}
      </div>
    );
  }

  /**
   * Updates resources state.
   * @param resources
   */
  updateResources(resources) {
    this.setState({ resources });
  }

  /**
   * Renders the team edit page given that the team and self object are loaded.
   *
   * Updates the point of contact me state property onc all data is loaded. Sets to true of the team has a point of contact and that is the logged on person.
   * @returns {*}
   */
  render() {
    const { team, self } = this.state;

    if (!team || !self) {
      return <PageLoader />
    }

    return (
      <div>
        {this.renderTopContent()}
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(TeamEditPage);
