import { getAccessToken, setAccessToken, analyticsTrack } from './utils.js';

const BASE_URL = (window.location.host.includes("localhost") || window.location.hostname.includes("staging")) ? (window.location.hostname.includes("staging") ? "https://trestle-staging.herokuapp.com" : "https://reparadocs.ngrok.io") : "https://api.ontrestle.com";

class ApiClient {
  static auth_fetch(path, args, no_args=false) {
    if (args['headers']) {
      args['headers']['Authorization'] = 'Token ' + getAccessToken();
    } else {
      args['headers'] = {'Authorization': 'Token ' + getAccessToken()};
    }
    if (no_args) {
      args = null;
    }
    return fetch(BASE_URL + "/v1" + path, args).then(data => {
      if (data.status === 401) { 
        data.json().then(data => {
          if (data['detail'] === 'Invalid token.') {
            setAccessToken('');
            window.location.reload();
          }
          return data;
        })
      } else {
        return data.json();
      }
    });
  }

  static login(username, password) {
    return fetch(BASE_URL + "/api/auth/login/", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(username + ':' + password)))
      },
      method: 'POST',
    }).then(data => data.json()).then(data => {
      if (!data["token"]) return false;
      setAccessToken(data["token"]);
      return true;
    });
  } 

  static get(path) {
    return this.auth_fetch(path, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  static no_auth_get(path) {
    return this.auth_fetch(path, {
      headers: {
        'Content-Type': 'application/json'
      }
    }, true);
  }

  static delete(path) {
    analyticsTrack('DELETE_' + path, {});
    return this.auth_fetch(path, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
    });
  }

  static post(path, data) {
    analyticsTrack('POST_' + path, data);
    return this.auth_fetch(path, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static upload_photo(path, file, field="uploaded_photo") {
    analyticsTrack('PHOTO_' + path, {});
    var data = new FormData();
    data.append(field, file);
    return this.auth_fetch(path, {
      body: data,
      method: 'PUT',
    });
  }
}

export default ApiClient;
