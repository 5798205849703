import React, { Component } from 'react';
import EventView from './components/EventView.react.js';
import AnnouncementsView from './components/AnnouncementsView.react';
import { analyticsPage } from '../../utils.js';
import SearchBar from '../shared/components/SearchBar.react';
import '../shared/styles/search.scss';
import './styles/homepage.scss';
import { getStorage, setStorage } from '../../utils.js';
import ApiClient from '../../ApiClient.js';
import PageLoader from '../shared/components/PageLoader.react.js';

class Homepage extends Component {
  constructor(props) {
    super(props);
    const storageBirthdays = JSON.parse(getStorage("birthdays"));
    const storageAnniversaries = JSON.parse(getStorage("anniversaries"));
    const storageResources = JSON.parse(getStorage("resources"));
    const storageNewHires = JSON.parse(getStorage("newHires"));
    this.state = {
      birthdays: storageBirthdays ? storageBirthdays : [],
      anniversaries: storageAnniversaries ? storageAnniversaries : [],
      resources: storageResources ? storageResources : [],
      newHires: storageNewHires ? storageNewHires : [],
      date: new Date()
    };
  }

  componentDidMount() {
    analyticsPage("homepage");
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    ApiClient.get(`/home/birthdays/${month}/${day}`)
      .then(birthdays => {
        setStorage("birthdays", JSON.stringify(birthdays));
        this.setState({ birthdays });
      })
      .catch(console.error);

    ApiClient.get(`/home/anniversaries/${year}/${month}/${day}`)
      .then(anniversaries => {
        setStorage("anniversaries", JSON.stringify(anniversaries));
        this.setState({ anniversaries });
      })
      .catch(console.error);
    
    ApiClient.get(`/home/resources`)
      .then(resources => {
        setStorage("resources", JSON.stringify(resources));
        this.setState({ resources });
      })
      .catch(console.error);
    
    ApiClient.get(`/home/new_hires`)
      .then(newHires => {
        setStorage("newHires", JSON.stringify(newHires));
        this.setState({ newHires });
      })
      .catch(console.error);
  }

  render() {
    const show_events = (this.state.anniversaries.length || this.state.birthdays.length || this.state.newHires.length) !== 0;

    if (!this.props.self) {
      return <PageLoader />;
    }
    return (
      <div>
        <div className="dark-blue-background mt-lg-0 pb-5" style={{
          minHeight: '250px'
        }}>
          <div style={{paddingTop: 20}} >
            <img src={this.props.self.company.logo || "/img/trestle-logo.png"} style={{ height: 60, marginBottom: 40 }} alt="company-icon"></img>
            <SearchBar search={this.props.search} placeholder="Search" class_prefix="homepage" focus={true} icon_position='right' />
            <div className="d-flex flex-row justify-content-center mt-4" style={{color: 'white', fontSize: 20}}>
            {
              this.state.resources.map((obj, index) =>
                <div key={`ResrtouceKey${index}`}
                     className="px-2 resources-link d-none d-sm-block"
                     style={index ? {borderLeft: '1px solid rgba(255, 255, 255, 0.5)'} : {}}>
                  <a href={obj.link} target="_blank" rel="noopener noreferrer">{obj.title}</a>
                </div>
              )
            }
            </div>
          </div>

        </div>

          <div className="homepage-content-wrapper mx-auto">
            <div  className="container-fluid">

            <div className="row">
              <AnnouncementsView col={show_events ? "8" : "12"} />
              {
                show_events &&
                <div className="col-12 col-lg-4 mx-auto mx-xl-0">
                  <EventView  date={this.state.date} birthdays={this.state.birthdays} anniversaries={this.state.anniversaries} newHires={this.state.newHires} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;