import React, { Component } from 'react';
import { analyticsPage, isDemo } from '../../utils.js';
import './styles/post.scss';
import ApiClient from '../../ApiClient.js';
import PageLoader from '../shared/components/PageLoader.react.js';
import ReactMarkdown from 'react-markdown';
import * as moment from 'moment';
import PostCommentWidget from './components/PostCommentWidget.react.js';
import CommentWidget from './components/CommentWidget.react';
import { Link } from 'react-router-dom';
import {Dialog} from 'evergreen-ui';

class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
      modal_shown: false, 
      delete_id: -1,
    };
    this.refreshData = this.refreshData.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    analyticsPage("post_page");
    this.refreshData();
  }

  refreshData() {
    ApiClient.get(`/posts/${this.props.post_id}`)
    .then(post => this.setState({ post }))
    .catch(console.error);
  }

  onDelete(post_id) {
    this.setState({modal_shown: true, delete_id: post_id});
  }

  render() {
    if (!this.state.post) {
      return <PageLoader />;
    }

    const post = this.state.post;
    const posted = moment(post.posted).fromNow();

    const can_edit = post.author.id === this.props.self.id || this.props.admin;
    const edit_button = can_edit ? <Link className="pr-2 post-clickable rl-none" to={`/post/${post.id}/edit`}> Edit </Link> : null;
    const delete_button = can_edit ? <div className="pr-2 post-clickable" onClick={() => this.onDelete(post.id)}> Delete </div> : null;

    return (
      <div>
        <Dialog
          style={{height: 300}}
          width={375}
          isShown={this.state.modal_shown}
          title="Delete Post"
          onCloseComplete={() => this.setState({modal_shown: false})}
          onConfirm={() => {
            ApiClient.delete(`/posts/${this.state.delete_id}`).then(() => this.refreshData());
            this.setState({modal_shown: false});

            if (this.state.delete_id === post.id) {
              this.props.history.push(`/`);  
            }
          }}
          confirmLabel="Delete"
        >
          Are you sure you want to delete this post? This cannot be undone.
        </Dialog>
        <div className="d-flex flex-wrap flex-row mx-auto mt-4 post-container" style={{maxWidth: 992}}>
        {post.announcement && <div className="post-announcement d-flex align-items-center justify-content-center"> <i className="fas fa-star" style={{fontSize: 10, marginTop: -1}}></i>&nbsp;Announcement</div>}

          <div className="d-flex flex-column col-11 align-items-start" style={{textAlign: 'left'}}>
            <h3>{post.title}</h3>
            <ReactMarkdown className="post-content" source={post.content} />
            <div className="d-flex flex-row py-2">
              {edit_button} {delete_button}
            </div>
            <div style={{fontSize: 14}}><b>{posted}</b></div>
            <Link to={`/people/${post.author.email}`} className="router-link rl-none d-flex flex-row light-gray-border post-author-card clickable grow my-3 align-items-center">
              <img src={post.author.photo} alt="author" className="post-author-photo p-2" />
              <div className="d-flex flex-column px-2">
                <div>{post.author.first_name} {post.author.last_name}</div>
                <div className="post-author-role">{post.author.role}</div>
              </div>
            </Link>
            {!isDemo() && <PostCommentWidget post_id={post.id} onSubmit={this.refreshData} />}
          {
            post.ordered_children.map((child, index) => 
              <CommentWidget depth={0} comment={child} refreshData={this.refreshData} person_id={this.props.self.id} admin={this.props.admin} onDelete={this.onDelete} key={`lvl_${index}`} _key={`lvl_${index}`}/>
            )
          }
          </div>
          
          </div>
          <div className="d-flex flex-column">
          
          </div>
      </div>
    );
  }
}

export default PostPage;
