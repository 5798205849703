import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, FormField, Textarea, Pane, Dialog, Label, toaster, Select } from 'evergreen-ui';
import * as moment from 'moment';
import { analyticsPage } from '../../utils.js'; 
import './styles/onboarding.scss';
import ApiClient from '../../ApiClient.js';
import Loader from 'react-loader-spinner';
import AlgoliaPlaces from 'algolia-places-react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { removeTrailingSlash } from '../shared/utils';
import PeopleSelector from '../shared/components/PeopleSelector.react';
import GroupSelector from '../shared/components/GroupSelector.react';

import DeactivateModal from '../shared/components/DeactivateModal.react';

// shared components
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';

import Section from '../shared/components/Section.react';
import { isValidEmail } from '../shared/utils';

class OnboardingPage extends Component {
  state = {
    person: null,
    src: null,
    crop: {
      aspect: 1,
      width: 50,
      height: 50,
      x: 0,
      y: 0,
    },
    loading: false,
    pictureLoading: false,
  };

  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    email: 'Email is not valid',
  };

  months = [
    { label: '-', value: 'null'},
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];
  monthNumberMap = {
    '0': '01',
    '1': '02',
    '2': '03',
    '3': '04',
    '4': '05',
    '5': '06',
    '6': '07',
    '7': '08',
    '8': '09',
    '9': '10',
    '10': '11',
    '11': '12',
  };
  socialMediaStrings = ['github.com/', 'twitter.com/', 'linkedin.com/in/'];

  constructor(props) {
    super(props);

    ApiClient.get(`/people/${this.props.person_id}`)
      .then(person => this.setState({ person }))
      .catch(err => this.onProfileUpdateError(err));
  }

  componentDidMount() {
    analyticsPage("person_edit");
  }

  /**
   * Submits the onboarding candidate's info.
   */
  onSubmit() {
    const { loading, person } = this.state;

    const {
      bio,
      birthday, slack, ah_email, ah_phone,
      first_name, last_name, role, manager, team,
      extra_fields, latitude, longitude, location, joined,
    } = person;
    let {
      twitter, github, linkedin,
    } = person;


    if (ah_email && !isValidEmail(ah_email)) {
      return toaster.danger(this.errors.email);
    }

    twitter = this.getNameFromSocial(twitter);
    github = this.getNameFromSocial(github);
    linkedin = this.getNameFromSocial(linkedin);

    if (loading) {
      return;
    } else {
      this.setState( { loading: true });
    }

    let payload = {
      bio,
      first_name, last_name, role,
      birthday, slack, twitter, github, linkedin, ah_email, ah_phone,
      extra_fields, latitude, longitude, location, joined,
    };

    if (!(typeof manager === 'object' && manager !== null)) {
      payload.manager = manager;
    }
    
    if (!(typeof team === 'object' && team !== null)) {
      payload.team = team;
    }
    

    ApiClient.post(`/people/${this.props.person_id}`, payload)
      .then(() => this.onProfileUpdated())
      .catch(err => this.onProfileUpdateError(err));
  }

  /**
   * Tries to find a social media profile name first part (e.g. twitter.com) and removes it to only leave the name.
   *
   * @param {string} profileName Full profile name
   * @returns {string} Parsed profile name
   */
  getNameFromSocial(profileName) {
    if (!profileName || typeof profileName !== 'string') {
      return '';
    }

    for (let i = 0; i < this.socialMediaStrings.length; i++) {
      const mediaStringIndex = profileName.indexOf(this.socialMediaStrings[i]);

      if (mediaStringIndex > -1) {
        const profileNameStartIndex = mediaStringIndex + this.socialMediaStrings[i].length;

        if (profileNameStartIndex < profileName.length) {
           return removeTrailingSlash(profileName.slice(profileNameStartIndex));
        }
      }
    }

    return profileName;
  }

  /**
   * Profile updated event handler.
   *
   * Navigates to the profile page.
   */
  onProfileUpdated() {
    this.setState( { loading: false });
    this.props.history.push(`/people/${this.props.person_id}`);
  }

  /**
   * Profile update error handler.
   * @param {*} err Error
   */
  onProfileUpdateError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { loading: false });
  }

  /**
   * Profile picture update error handler.
   * @param {*} err Error
   */
  onProfilePictureUpdateError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { pictureLoading: false });
  }

  uploadHandler(file) {
    this.setState({ pictureLoading: true });

    return ApiClient
      .upload_photo("/people/" + this.props.person_id + "/upload_photo", file)
      .then(data => this.onPictureUploaded(data))
      .catch(err => this.onProfilePictureUpdateError(err));
  }

  /**
   * Picture added handler.
   */
  onPictureUploaded(data) {
    const { person } = this.state;

    person.photo = data['uploaded_photo'];
    this.setState({ person, pictureLoading: false });
  }

  /**
   * Form property changes handler.
   *
   * Saves the new value to the profile state.
   * @param {string} propName Property name
   * @param {*} propValue Property value
   */
  onPropChanged(propName, propValue) {
    const { person } = this.state;

    person[propName] = propValue;

    this.setState({ person })
  }

  /**
   * Extra field value changed handler.
   *
   * Finds the extra field that was updated and
   * @param {string} label
   * @param {*} value
   * @param {int} index of the field in the array
   */
  onExtraFieldChanged(label, value, index) {
    const { person } = this.state;
    const { extra_fields } = person;

    extra_fields[index] = {label: label, value: value};

    this.setState({ person });
  }

  /**
   * Renders the top content.
   */
  renderTopContent() {
    const { person, loading } = this.state;
    const { first_name } = person;
    const title = `Hi ${first_name}, welcome to Trestle!`;
    const subTitle = 'Please make sure that we have the correct information and fill out any missing fields.';

    return (
      <PageTopContent
        title={title}
        subTitle={subTitle}>
        <img src="/img/mascot/Hello-Pose.png" alt="mascot" className="header-image" height={128} style={{marginLeft: 20, marginTop: -20}} />
        <div className="ml-auto mt-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto save-top"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40} />
            }
          </Button>
        </div>
      </PageTopContent>
    );
  }

  /**
   * Files selected event handler.
   *
   * Sets the src as the selected image.
   * @param e
   */
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          src: reader.result,
        })
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  /**
   * On change image clicked handler.
   *
   * Shows the crop image dialog.
   */
  onChangeImageClicked() {
    this.setState({
      isShown: true,
    })
  }

  /**
   * Profile image loaded event handler.
   *
   * @param image
   * @param pixelCrop
   */
  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;

    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;

    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop, height: null },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  };

  /**
   * User cropping movement done.
   *
   * @param crop
   * @param pixelCrop
   */
  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  /**
   * On crop dimensions changed event handler.
   *
   * @param crop
   */
  onCropChange = crop => {
    this.setState({ crop });
  };

  /**
   * Crops the selected image with the specified cropping params.
   *
   * @param crop
   * @param pixelCrop
   * @returns {Promise<void>}
   */
  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageFile = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageFile });
    }
  }

  /**
   * Creates the cropped image blob.
   *
   * @param image
   * @param pixelCrop
   * @param fileName
   * @returns {Promise<any>}
   */
  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        resolve(blob);
      }, 'image/jpeg');
    });
  }


  /**
   * Crop image confirm handler.
   *
   * Uploads the cropped image to user profile.
   */
  onCropConfirmed() {
    this.setState({ isLoading: true });
    this.uploadHandler(this.state.croppedImageFile)
      .then(() => this.setState({
        isShown: false,
        isLoading: false,
      }));
  }

  /**
   * Renders the fields used in the general section.
   */
  renderGeneralSectionContent() {
    const { person, pictureLoading } = this.state;
    const {
      photo, first_name, last_name, team, role, bio,
      manager, joined, location
    } = person;
    const pictureStyle = {
      width: '100%',
      maxWidth: '200px',
      borderRadius: '5px',
    };
    const disabledFields = this.props.disabledFields;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-3 col-xl-2 text-center">
            {
              !pictureLoading && photo &&
              <img src={photo}
                   className="mb-3"
                   alt="profile"
                   style={pictureStyle}/>
            }
            {
              !pictureLoading && !photo &&
              <div className="light-gray-background mx-auto my-2"
                   style={{
                     ...pictureStyle,
                     width: '90px',
                     height: '90px'
                   }}></div>
            }
            {
              pictureLoading &&
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Loader type="ThreeDots" width={50} />
              </div>
            }

            {
              this.state.src &&
              <Pane>
                <Dialog
                  isShown={this.state.isShown}
                  title="Crop Picture"
                  onCloseComplete={() => this.setState({ isShown: false })}
                  onConfirm={() => this.onCropConfirmed()}
                  isConfirmLoading={this.state.isLoading}
                >
                  <Pane width="100%" >
                    {this.state.src && (
                      <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                  </Pane>

                </Dialog>
              </Pane>
            }

          </div>

          <div className="col-12 col-md-6">

            <Button
              onClick={() => this.onChangeImageClicked()}
                appearance="default"
                className="mx-auto ml-md-0 mb-3 mt-1 mb-sm-0 mt-sm-3"
                style={{
                  width: '125px'
                }}>
              <label htmlFor="file-upload"
                     className="mb-0 d-flex justify-content-center align-items-center"
                     style={{
                       cursor: 'pointer',
                       width: '100%',
                       height: '100%'
                     }}>
                <input id="file-upload"
                    accept="img/*"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => this.onSelectFile(e) } />
                Change Picture
              </label>
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <TextInputField
              disabled={disabledFields.indexOf('first_name') > -1}
              value={first_name}
              onChange={event => this.onPropChanged('first_name', event.target.value)}
              label="First Name"
            />
          </div>

          <div className="col-12 col-md-6">
            <TextInputField
              disabled={disabledFields.indexOf('last_name') > -1}
              value={last_name}
              onChange={event => this.onPropChanged('last_name', event.target.value)}
              label="Last Name"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
          {disabledFields.indexOf('team') <= -1 &&
              <GroupSelector 
                label="Team" 
                is_team={true}
                initialGroup={team} 
                searchKey={this.props.searchKey}               
                onSelectGroup={(group) => this.onPropChanged('team', group.objectID ? parseInt(group.objectID) : null)} 
              />
            }
            {
              disabledFields.indexOf('team') > -1 &&
              <TextInputField
                disabled
                value={team ? team.name : ''}
                label="Team"
              />
            }
            
          </div>

          <div className="col-12 col-md-6">
            <TextInputField
              disabled={disabledFields.indexOf('role') > -1}
              value={role}
              onChange={event => this.onPropChanged('role', event.target.value)}
              label="Title"
            />
          </div>
        </div>

        <Pane marginBottom={10}>
          <Label
            htmlFor="textarea-bio"
            marginBottom={4}
            display="block"
          >
            Bio
          </Label>
          <Textarea
            id="textarea-bio"
            placeholder="Bio"
            value={bio}
            onChange={event => this.onPropChanged('bio', event.target.value)}
            maxLength={400}
            height={90}
          />
        </Pane>

        <div className="row">
          {this.props.self.company.manager && <div className="col-12 col-sm-6">
            {disabledFields.indexOf('manager') <= -1 &&
              <PeopleSelector 
                label="Manager" 
                clear={true} 
                initialPerson={manager} 
                searchKey={this.props.searchKey}               
                onSelectPerson={(person) => this.onPropChanged('manager', person.objectID ? parseInt(person.objectID) : null)} 
              />
            }
            {
              disabledFields.indexOf('manager') > -1 &&
              <TextInputField
                disabled
                value={manager ? this.getFullName(manager) : ''}
                label="Manager"
              />
            }

          </div>}

          {this.props.self.company.joined && <div className="col-12 col-sm-6">
            <div className="mb-3">
              <DatePicker
                id="joined-on-date-picker"
                disabled={disabledFields.indexOf('joined') > -1}
                selected={joined ? moment(joined, 'YYYY-MM-DD') : null}
                selectsStart
                onChange={date => {
                  if (moment(date, 'YYYY-MM-DD').isValid()) {
                    this.onPropChanged('joined', moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                  }
                }}
                customInput={
                  <TextInputField
                    margin={0}
                    value={moment(joined, 'YYYY-MM-DD')}
                    label="Joined on"
                  />
                }
              />
            </div>
          </div>}
        </div>
        <FormField label="Location" />
        <AlgoliaPlaces
          placeholder={location}
          options={{
            appId: 'plVNKE2GYVYF',
            apiKey: '27e0daec374acf20304c26fbe1c38910',
            type: 'city',
          }}

          onChange={({ query, rawAnswer, suggestion, suggestionIndex }) => 
          {
            let finalLoc = suggestion.name + ", "
            if (suggestion.countryCode === "us") {
              finalLoc += suggestion.administrative
            } else {
              finalLoc += suggestion.country
            }
            const { person } = this.state;

            person['location'] = finalLoc;
            person['longitude'] = suggestion.latlng.lng;
            person['latitude'] = suggestion.latlng.lat;
        
            this.setState({ person });
         }}
        />

      </div>
    );
  }

  /**
   * Concats the first and the last names of a person.
   *
   * Makes sure there are no exmpty spaces around the full name.
   *
   * @param {Object} Object containing the first and last names of the person
   * @returns {string} Concatenated name
   */
  getFullName({ first_name, last_name }) {
    let fullName = '';

    if (first_name) {
      fullName += `${first_name} `;
    }

    if (last_name) {
      fullName += last_name;
    }

    return fullName.trim();
  }

  /**
   * Sets the new birthday.
   *
   * If the passed prop is a month, then uses that month. Otherwise the current month.
   * If the passed prop is day then uses that prop, otherwise the current day.
   *
   * @param prop
   * @param value
   */
  onBirthdateChanged(prop, value) {
    const { person } = this.state;
    if (value === 'null' && (prop === 'month' || prop === 'day')) {
      person.birthday = null;
      this.setState({person});
      return;
    }
    const year = 1995; // Doesn't matter
    const month = prop === 'month' ? value : this.getMonthValue();
    const day = prop === 'day' ? value : this.getDayValue();
    const birthday = moment(
      `${year}-${month}-${day}`,
      'YYYY-MM-DD'
    )
      .format('YYYY-MM-DD')
      .toString();

    person.birthday = birthday;
    this.setState({ person })
  }

  /**
   * Since moment returns the 0-indez number of the month, need to convert it to a string (e.g. 0 is 01).
   * @returns {*}
   */
  getMonthValue() {
    const { birthday } = this.state.person;
    return birthday ? this.monthNumberMap[moment(birthday, 'YYYY-MM-DD').get('month')] : '-';
  }

  /**
   * Get's the current birthdate's date.
   * @returns {*}
   */
  getDayValue() {
    const { birthday } = this.state.person;

    return birthday ? moment(birthday, 'YYYY-MM-DD').get('date') : '-';
  }

  getDaysOfMonthOptions() {
    const days = [ <option key={`nullBirthdayDayOptionKey`} value={'null'}>-</option>];

    for (let i = 1; i <= 31; i++) {
      days.push(
        <option key={`${i}BirthdayDayOptionKey`}
                value={i}>{i}</option>
      );
    }

    return days;
  }

  /**
   * Renders the fields used in the social section.
   */
  renderSocialSectionContent() {
    let {
      slack, github, twitter, linkedin, ah_phone, ah_email, email
    } = this.state.person;

    ah_email = ah_email ? ah_email : '';
    ah_phone = ah_phone ? ah_phone : '';
    
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6">
            <TextInputField
              disabled
              value={email}
              placeholder="None :("
              label="Email"
            />
          </div>

          <div className="col-12 col-sm-6">
            <TextInputField
              disabled
              value={slack}
              placeholder="None :("
              label="Slack"
            />
          </div>
        </div>
        <div className="row">
        
        {this.props.self.company.birthday && <div className="col-12 col-sm-6">

            <Pane marginBottom={10}>
              <Label
                htmlFor="birthday-month-picker"
                marginBottom={4}
                display="block"
              >
                Birthday
              </Label>

              <div id="birthday-month-picker"
                   className="d-flex flex-row justify-content-between">
                <Select value={this.getMonthValue()}
                        onChange={event => this.onBirthdateChanged('month', event.target.value)}>
                  {this.months.map(({ label, value }, index) => {
                    return(
                      <option key={`${index}BirthdayMonthOptionKey`}
                              value={value}>{label}</option>
                    );
                  })}
                </Select>

                <Select value={this.getDayValue()}
                        className="ml-1"
                        onChange={event => this.onBirthdateChanged('day', event.target.value)}>
                  {
                    this.getDaysOfMonthOptions()
                  }
                </Select>
              </div>
            </Pane>
          </div>}

          <div className="col-12 col-sm-6">
            <TextInputField
              onChange={event => this.onPropChanged('linkedin', event.target.value)}
              value={linkedin}
              label="Linkedin"
            />
          </div>
        </div>

        <div className="row">
          {this.props.self.company.github && <div className="col-12 col-sm-6">
            <TextInputField
              onChange={event => this.onPropChanged('github', event.target.value)}
              value={github}
              label="Github"
            />
          </div>}

          <div className="col-12 col-sm-6">
            <TextInputField
              onChange={event => this.onPropChanged('twitter', event.target.value)}
              value={twitter}
              label="Twitter"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6">
            <TextInputField
              isInvalid={Boolean(ah_email && !isValidEmail(ah_email))}
              onChange={event => this.onPropChanged('ah_email', event.target.value)}
              value={ah_email}
              label="Afterhours email"
            />
          </div>

          <div className="col-12 col-sm-6">
            <TextInputField
              onChange={event => this.onPropChanged('ah_phone', event.target.value)}
              value={ah_phone}
              placeholder="(321)-555-1212"
              label="Afterhours phone"
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders
   */
  renderExtraFieldsSectionContent() {
    const { extra_fields } = this.state.person;

    return(
      <div className="container-fluid">
        <div className="row">
          {
            extra_fields
              .map(({ label, value }, index) => {
                return(
                  <div key={`${index}ExtraFieldRow`}
                       className="col-12 col-sm-6">
                    <TextInputField
                      onChange={event => this.onExtraFieldChanged(label, event.target.value, index)}
                      value={value}
                      label={label}
                    />
                  </div>
                );
              })
          }
        </div>
      </div>
    );
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { person, loading } = this.state;
    const { extra_fields } = person;

    const deactivatePersonModal = 
      <DeactivateModal 
        title="Deactivate Person" 
        submitUrl={"/people/" + this.props.person_id + "/active"}
        prompt={"Are you sure you want to deactivate " + person.email + "? This person will no longer show up in Trestle. You can reactivate users from the Admin dashboard."}
        ref="deactivate_person_modal" 
        submitSuccess={() => this.props.history.push('/')}
      />;

    return (
      <div className="text-left">
        <Section title="General"
                 subtitle="Please contact us or a company admin if a disabled field is incorrect. (We're really sorry!)">
          {this.renderGeneralSectionContent()}
        </Section>

        <Section title="Social"
                 subtitle="">
          {this.renderSocialSectionContent()}
        </Section>

        {
          extra_fields &&
          extra_fields.length > 0 &&
          <Section title="Custom"
                   subtitle="">
            {this.renderExtraFieldsSectionContent()}
          </Section>
        }

        <div className="full-width-item mx-auto d-flex flex-row align-items-right">
          {
            this.props.admin &&
            <Button 
              onClick={() => this.refs.deactivate_person_modal.showModal()}
              className="mr-auto my-4 ml-3"
              style={{
                backgroundColor: '#fb3434',
                width: '100px'
              }}
              appearance="primary">
              Deactivate 
             </Button>
            
          }
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto my-4 mr-3"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40} />
            }
          </Button>
        </div>
        {deactivatePersonModal}
      </div>
    );
  }

  render() {
    if (!this.state.person) {
      return <PageLoader />
    }

    return (
      <div>
        {this.renderTopContent()}
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(OnboardingPage);
