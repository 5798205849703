import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import ApiClient from '../../../ApiClient.js';
import { Textarea, toaster } from 'evergreen-ui';
import Button from '../../shared/components/Button.react';
import Loader from 'react-loader-spinner';

class PostCommentWidget extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content ? this.props.content : '', 
      submitClicked: false
    };
  }

  validations = {
    minLength: 1,
    maxLength: 5000,
  };

  validators = {
    content: (content) => content.length <= this.validations.minLength || content.length > this.validations.maxLength,
  }

  onSubmit() {
    const url = this.props.comment_id ? `/posts/comment/${this.props.comment_id}` : `/posts/${this.props.post_id}/comment`;
    
    if (this.state.submitClicked) {
      return;
    }

    if (this.validators.content(this.state.content)) {
      return toaster.danger("Can't submit a blank comment");
    }

    this.setState({ submitClicked: true });

    ApiClient.post(url, {content: this.state.content})
      .then((data) => {this.setState({submitClicked: false, content: ''}); this.props.onSubmit();});
  }

  render() {
    return (
      <div className={"d-flex flex-column align-items-start post-response " + this.props.className}>
        <Textarea
              id="description"
              isInvalid={this.state.submitClicked && this.validators.content(this.state.content)}
              style={{marginBottom: 24}}
              placeholder="Comment"
              value={this.state.content}
              maxLength={this.validations.maxLength}
              onChange={event => this.setState({content: event.target.value})}
            />
            <div className="d-flex flex-row">
            <Button className="post-button" onClick={() => this.onSubmit()}>{this.state.submitClicked ? <Loader type="ThreeDots" color="#fff" width={40}/> : "Submit"}</Button>
            {
              this.props.onClose && 
              <Button className="post-button mx-2" style={{color: 'black', backgroundColor: '#d8d8d8', boxShadow: 'none'}} onClick={() => this.props.onClose()}>Cancel</Button>
            }
            </div>
          
      </div>
    );
  }
}

export default withRouter(PostCommentWidget);
