import React, { Component } from 'react';
import ApiClient from '../../../ApiClient.js';
import { Text } from 'evergreen-ui';

class TeamResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
    };
    this.refreshData = this.refreshData.bind(this);
  }
  
  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    ApiClient.get("/groups/" + this.props.team_id + "/resources").then(
      data => { console.log(data); this.setState({ resources: data }); }
    ); 
  }

  render() {
    let resources = this.state.resources.map((resource, index, arr) =>
      <div key={"res" + index}
           className="team-resource-link d-flex align-items-center justify-content-start ml-3"
           style={index === 3 ? {borderBottom: "none", paddingRight: 10} : {paddingRight: 10}}>
        <a href={resource.link} target="_blank" rel="noopener noreferrer" className="text-left">
          <Text className="text-left"
                width="100%">
            <span className="white-text">
              {resource.title}
            </span>
            <i className="fas fa-chevron-right mr-3 ml-auto mt-1" style={{
              float: 'right'
            }}/>
          </Text>
        </a>
      </div>
    );

    if (!resources.length && (this.props.team.in_group || this.props.self.is_company_admin)) {
      resources = 
        <div className="add-resources-button d-flex align-items-center justify-content-center m-auto"
             onClick={() => this.props.openEdit()}>
          Add Resources
        </div>;
    }
    return (
      <div className="team-resource-container d-flex flex-column ml-md-5 mt-3 mt-md-0">
        <div className="team-resource-header d-flex align-items-center justify-content-center">
          Resources
        </div>
        {resources}
      </div>
    );
  }
}

export default TeamResources;