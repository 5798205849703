import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, Label, Textarea, toaster } from 'evergreen-ui';
import ApiClient from '../../ApiClient.js';
import Loader from 'react-loader-spinner';

// shared components
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';
import Section from '../shared/components/Section.react';
import { analyticsPage } from '../../utils.js';

class AnnouncementEditPage extends Component {
  state = {
    announcement: null,
    submitClicked: false,
    loading: false,
  };
  errorMessage = 'An error occurred';

  /**
   * Link to the back-end.
   */
  apiUrl = '/home/announcements';

  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
    title: 'Name is not valid',
    content: 'Content is not valid',
  };

  /**
   * Validation values.
   */
  validations = {
    minLength: 1,
    maxContent: 5000,
  };

  /**
   * Retrieves the team and self objects.
   */
  constructor(props) {
    super(props);

    const { announcement_id } = this.props;

    if (announcement_id) {
      ApiClient.get(`${this.apiUrl}/${announcement_id}`)
        .then(data => this.onAnnouncementRetrieved(data))
        .catch(err => this.onError(err));
    } else {
      this.state.announcement = this.createNewAnnouncement();
    }
  }

  componentDidMount() {
    analyticsPage("admin_announcements_edit");
  }

  /**
   * Determines if the team / group is being created or edited.
   * @returns {boolean}
   */
  isCreating() {
    return typeof this.props.announcement_id === 'undefined';
  }

  /**
   * Data retrieved event handler.
   *
   * @param {Object} announcement
   */
  onAnnouncementRetrieved(announcement) {
    this.setState({ announcement });
  }

  /**
   * Adds an is_team property to the new team based on the property that is passed in the route.
   *
   * @returns {{is_team: boolean}}
   */
  createNewAnnouncement() {
    return {
      title: '',
      content: '',
      pinned: false,
    };
  }

  /**
   * Submits the team/groups's info.
   *
   * Sets the submitClicked flat to true.
   */
  onSubmit() {
    const { announcement, loading } = this.state;

    const {
      id, title, content, pinned
    } = announcement;
    const payload = {
      title, content, pinned
    };
    const url = this.isCreating() ? this.apiUrl : `${this.apiUrl}/${id}`;

    this.setState({ submitClicked: true });

    // validate properties
    if (title.length < this.validations.minLength) {
      return toaster.danger(this.errors.title);
    }

    if (this.isContentInvalid()) {
      return toaster.danger(this.errors.content);
    }

    if (loading) {
      return;
    } else {
      this.setState({ loading: true });
    }

    ApiClient.post(url, payload)
      .then(() => this.onAnnouncementUpdated())
      .catch(err => this.onError(err));
  }

  /**
   * Validates if the announcement's content is valid.
   *
   * @returns {boolean}
   */
  isContentInvalid() {
    const {
      content
    } = this.state.announcement;

    return content.length <= this.validations.minLength || content.length > this.validations.maxContent
  }
  /**
   * Announcement updated event handler.
   *
   * Navigates to the announcements page.
   */
  onAnnouncementUpdated() {
    this.setState({ loading: false });
    this.props.history.push('/admin/announcements');
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errorMessage);
    this.setState({ loading: false });
  }

  /**
   * Form property changes handler.
   *
   * @param {string} propName Property name
   * @param {*} propValue Property value
   */
  onPropChanged(propName, propValue) {
    const { announcement } = this.state;

    announcement[propName] = propValue;

    this.setState({ announcement })
  }


  /**
   * Renders the top content.
   *
   * If a new team/group is being created then adjusts the title accordingly.
   */
  renderTopContent() {
    const title = this.isCreating() ? 'Crete Annoucement' : `Edit Annoucement: ${this.state.announcement.title}`;
    const { loading } = this.state;

    return (
      <PageTopContent
        title={title}>
        <div className="ml-auto mt-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40}/>
            }
          </Button>
        </div>
      </PageTopContent>
    );
  }

  /**
   * Renders the fields used in the general section.
   *
   * Only renders the name text field if a new team/group is being created.
   */
  renderGeneralSection() {
    const { announcement, submitClicked } = this.state;
    const {
      title, content
    } = announcement;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInputField
              isInvalid={submitClicked && title.length <= this.validations.minLength}
              label="Name"
              defaultValue={title}
              placeholder="Name"
              onChange={event => this.onPropChanged('title', event.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Label
              htmlFor="content"
              marginBottom={4}
              display="block"
            >
              Content
            </Label>

            <Textarea
              id="description"
              isInvalid={submitClicked && this.isContentInvalid()}
              style={{marginBottom: 24}}
              placeholder="Content"
              defaultValue={content}
              maxLength={this.validations.maxContent}
              onChange={event => this.onPropChanged('content', event.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { loading } = this.state;

    return (
      <div className="text-left">
        <Section title="General">
          {this.renderGeneralSection()}
        </Section>

        <div className="full-width-item mx-auto">
          <Button
            onClick={() => this.onSubmit()}
            appearance="primary"
            className="ml-auto my-4 mr-3"
            style={{
              width: '80px'
            }}>
            {
              !loading &&
              'Save'
            }
            {
              loading &&
              <Loader type="ThreeDots" color="#fff" width={40}/>
            }
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Renders the team edit page given that the team and self object are loaded.
   *
   * Updates the point of contact me state property onc all data is loaded. Sets to true of the team has a point of contact and that is the logged on person.
   * @returns {*}
   */
  render() {
    const { announcement } = this.state;

    if (!announcement) {
      return <PageLoader />
    }

    return (
      <div>
        {this.renderTopContent()}
        {this.renderBottomContent()}
      </div>
    );
  }
}

export default withRouter(AnnouncementEditPage);
