import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, Switch, toaster } from 'evergreen-ui';
import './styles/settings.scss';
import ApiClient from '../../ApiClient.js';
import Loader from 'react-loader-spinner';

// shared components
import PageLoader from '../shared/components/PageLoader.react';
import Button from '../shared/components/Button.react';
import Section from '../shared/components/Section.react';
import PeopleSelector from '../shared/components/PeopleSelector.react';
import { getFullName, analyticsPage, analyticsTrack } from '../../utils';
import GroupSelector from '../shared/components/GroupSelector.react';

class AdminSettingsTab extends Component {
  state = {
    settings: null,
    new_custom_fields: [],
    admins: [],
    deactivated: [],
    deactivated_groups: [],
    selectedPerson: null,
    selectedActivate: null,
    selectedGroup: null,
    loading: false,
    pictureLoading: false,
    github: this.props.self.company.github,
    manager: this.props.self.company.manager,
    joined: this.props.self.company.joined,
    birthday: this.props.self.company.birthday,
    daily_digest: this.props.self.company.daily_digest,
    email_all_posts: this.props.self.company.email_all_posts,
  };

  /**
   * Error messages.
   */
  errors = {
    unexpected: 'An error occurred',
  };

  constructor(props) {
    super(props);

    const $settings = ApiClient.get(`/admin/settings`)
      .catch(err => this.onError(err));
    const $admins = ApiClient.get('/admin/admins')
      .catch(err => this.onError(err));
    const $deactivated = ApiClient.get('/admin/deactivated')
    const $deactivated_groups = ApiClient.get('/admin/deactivated_groups')

    Promise.all([$settings, $admins, $deactivated, $deactivated_groups])
      .then(data => this.onDataRetrieved(data));
  }

  componentDidMount() {
    analyticsPage("admin_settings");
  }

  /**
   * Data retrieved handler.
   *
   * @param settings Admin settings
   * @param admins List of admins
   */
  onDataRetrieved([settings, admins, deactivated, deactivated_groups]) {
    this.setState({ settings, admins, deactivated, deactivated_groups});
  }

  /**
   * Submits the company's settings and resrouces.
   *
   * Adds valid new custom fields.
   * Validates resources (links and titles).
   */
  onSubmit() {
    let { categories } = this.state;

    const { settings, new_custom_fields, loading, github, manager, joined, birthday, daily_digest, email_all_posts } = this.state;


    if (loading) {
      return;
    } else {
      this.setState( { loading: true });
    }

    const {
      name, logo, custom_fields
    } = settings;

    this.setState({ submitClicked: true });

    // only save new custom fields that have a non-empty string for name
    const validNewFields = new_custom_fields.filter(({ name }) => !!name);
    custom_fields.push(...validNewFields);
    this.setState({new_custom_fields: []});

    const payload = {
      name, logo, custom_fields, github, manager, joined, birthday, daily_digest, email_all_posts
    };

    const $settings = ApiClient.post(`/admin/settings`, payload)
      .catch(err => this.onError(err));

    const $categories = ApiClient.post(`/home/resources`, categories)
      .catch(err => this.onError(err));

    Promise.all([$settings, $categories])
      .then(data => this.onSettingsChanged(data));
  }

  /**
   * Profile updated event handler.
   *
   * Navigates to the profile page.
   */
  onSettingsChanged(data) {
    this.setState( { loading: false });
    this.props.onSettingsChange();
    this.props.history.push('');
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { loading: false });
  }

  /**
   * Unexpected error handler.
   * @param {*} err Error
   */
  onPictureUploadError(err) {
    console.error(err);
    toaster.danger(this.errors.unexpected);
    this.setState( { pictureLoading: false });
  }

  /**
   * Uploads the company logo.
   * @param file
   */
  uploadHandler(file) {
    this.setState({ pictureLoading: true });

    ApiClient
      .upload_photo("/admin/settings/upload_logo", file, 'uploaded_logo')
      .then(data => this.onLogoUpdated(data))
      .catch(err => this.onPictureUploadError(err));
  }

  /**
   * Picture added handler.
   */
  onLogoUpdated(data) {
    const { settings } = this.state;

    settings.logo = data['uploaded_logo'];
    this.setState({ settings, loading: false });
    this.props.onSettingsChange();
  }

  /**
   * Form property changes handler.
   *
   * Saves the new value to the settings state.
   * @param {string} propName Property name
   * @param {*} propValue Property value
   */
  onPropChanged(propName, propValue) {
    const { settings } = this.state;

    settings[propName] = propValue;

    this.setState({ settings })
  }

  onChangeAdmin(person_id, is_admin) {
    ApiClient.post("/admin/admins", {person: person_id, is_admin: is_admin})
      .then((data) => ApiClient.get("/admin/admins").then((data) => this.setState({admins: data})));
  }

  /**
   * Custom field value changed handler.
   *
   * Finds the custom field that was updated and saves it to the state.
   *
   * If it is an existing field only the active flag can be changed.
   * If it is a new field both name and active flag can be changed.
   *
   * @param {string} name Name of the custom field
   * @param {*} active Flag showing if the custom field is active
   * @param {int} index of the field in the array
   * @param {boolean} isExistingField Flag showing if it is an existing field or not
   */
  onCustomFieldChanged(name, active, index, isExistingField) {
    const { settings, new_custom_fields } = this.state;
    const { custom_fields } = settings;

    if (isExistingField) {
      custom_fields[index].active = active;

      this.setState({ settings });
    } else {
      new_custom_fields[index] = {name, active};

      this.setState({ new_custom_fields });
    }
  }

  /**
   * Renders the fields used in the main section.
   */
  renderMainSection() {
    const {
      name,
    } = this.state.settings;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInputField
              onChange={event => this.onPropChanged('name', event.target.value)}
              value={name}
              label="Company Name"
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the fields used in the logo section.
   */
  renderLogoSection() {
    const { settings, pictureLoading } = this.state;
    const {
      logo,
    } = settings;
    const pictureStyle = {
      width: '100%',
      maxWidth: '200px',
      borderRadius: '5px',
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-3 text-center">
            {
              !pictureLoading && logo &&
              <div className="dark-blue-background d-flex align-items-center justify-content-center pb-0 p-2"
                   style={{
                     borderRadius: '3px',
                   }}>
                <img src={logo}
                   alt="profile"
                   style={pictureStyle}/>
              </div>
            }
            {
              !pictureLoading && !logo &&
              <div className="light-gray-background mx-auto my-2"
                   style={{
                     ...pictureStyle,
                     width: '90px',
                     height: '90px'
                   }}></div>
            }
            {
              pictureLoading &&
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Loader type="ThreeDots" width={50} />
              </div>
            }

          </div>

          <div className="col-12 col-md-6">
            <Button
              appearance="default"
              className="mx-auto ml-md-0 mb-3 mt-1 mb-sm-0"
              style={{
                width: '125px'
              }}>
              <label htmlFor="file-upload"
                     className="mb-0 d-flex justify-content-center align-items-center"
                     style={{
                       cursor: 'pointer',
                       width: '100%',
                       height: '100%'
                     }}>
              <input id="file-upload"
                     accept="img/*"
                     type="file"
                     style={{ display: 'none' }}
                     onChange={e => this.uploadHandler(e.target.files[0]) } />
              
                Change Logo
              </label>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Add custom field event handler.
   *
   * Adds an empty custom field.
   * Makes sure the custom fields array exists.
   */
  onAddCustomFieldClicked() {
    const { new_custom_fields } = this.state;

    new_custom_fields.push(
      { name: '', active: true },
    );

    this.setState({ new_custom_fields });
  }

  /**
   * Renders the custom fields section.
   */
  renderCustomFieldsSection() {
    const { settings, new_custom_fields = [] } = this.state;
    const { custom_fields = [] } = settings;

    return(
      <div className="d-flex flex-column p-2">
        {
          custom_fields
            .map((customField, index) => this.renderCustomFields(customField, index))
        }
        {
          new_custom_fields
            .map((customField, index) => this.renderCustomFields(customField, index, false))
        }
        <Button
          onClick={() => this.onAddCustomFieldClicked()}
          appearance="default"
          className=""
          style={{
            width: '100%'
          }}>Add Custom Field</Button>
      </div>
    );
  }

  /**
   * Renders custom fields.
   * Only allows editing the name for new custom fields.
   *
   * @param {Object} customField
   * @param {int} index
   * @param {boolean} isExistingField Flag showing if the field is an existing one or not
   * @returns {*}
   */
  renderCustomFields({ name, active }, index, isExistingField = true) {
    return(
      <div key={`${index}CustomFieldRow`}
           className="d-flex flex-row justify-content-between align-items-center">
        <div className="mr-3" style={{
          width: "100%",
        }}>
          <TextInputField
            disabled={isExistingField}
            onChange={event => isExistingField ? undefined : this.onCustomFieldChanged(event.target.value, active, index, isExistingField) }
            value={name}
            label="Field Name"
            width="100%"
          />
        </div>

        <Switch
      checked={active === true}
      onChange={event => this.onCustomFieldChanged(name, event.target.checked, index, isExistingField)}
    />
      </div>
    );
  }

  renderField(field) {
    console.log(this.state);
    return (
<div className="d-flex flex-row align-items-center">
        <div className="col-4">

        <p>{field}</p>
        </div>
<Switch
      checked={this.state[field.toLowerCase()]}
      onChange={event => this.setState({[field.toLowerCase()]: event.target.checked})}
    />
    </div>
    );
    
    
  }

  /**
   * Renders the settings tab content.
   */
  renderContent() {
    const activatePerson = (person) => {
      ApiClient.post("/people/" + person.email + "/active", {active: true}).then((data) => {
        ApiClient.get("/admin/deactivated").then((data) => this.setState({deactivated: data}));
        toaster.success("User Activated");
      });
    }

    const activateGroup = (group) => {
      ApiClient.post("/groups/" + group.id + "/active", {active: true}).then((data) => {
        ApiClient.get("/admin/deactivated_groups").then((data) => this.setState({deactivated_groups: data}));
        toaster.success("Group Activated");
      });
    }

    const admins = this.state.admins.map((person, index) =>
      <div key={`AdminPersonRowKey${index}`}>
        {getFullName(person)} 
        {this.props.self.id !== person.id && <span 
          style={{float: 'right', marginRight: 20, color: 'red', cursor: 'pointer'}}
          onClick={() => this.onChangeAdmin(person.id, false)}>Remove</span>
        }
        <div style={{borderBottom: '1px solid #8080806e', padding: 5, marginBottom: 10}} />
      </div>)
    
    const deactivated_users = this.state.deactivated.map((person, index) =>
      <div key={`DeactivatedPersonRowKey${index}`}>
        {getFullName(person)} 
        <span 
          style={{float: 'right', marginRight: 20, color: 'red', cursor: 'pointer'}}
          onClick={() => activatePerson(person)}>Activate</span>
        <div style={{borderBottom: '1px solid #8080806e', padding: 5, marginBottom: 10}} />
      </div>)
    
    const deactivated_groups = this.state.deactivated_groups.map((group, index) =>
      <div key={`DeactivatedGroupRowKey${index}`}>
        {group.name} 
        <span 
          style={{float: 'right', marginRight: 20, color: 'red', cursor: 'pointer'}}
          onClick={() => activateGroup(group)}>Activate</span>
        <div style={{borderBottom: '1px solid #8080806e', padding: 5, marginBottom: 10}} />
      </div>)

    const addSelectedPerson = () => {
      if (this.state.selectedPerson) this.onChangeAdmin(parseInt(this.state.selectedPerson.objectID), true)
    };

    return (
      <div className="text-left">
        <Section title="Main"
                 subtitle="">
          {this.renderMainSection()}
        </Section>

        <Section title="Company Logo"
                 subtitle="For best results, we recommend an logos of 240x80 pixels">
          {this.renderLogoSection()}
        </Section>
        <Section title="Connect to Slack">
          {this.props.self.company.slack_id && <span style={{fontSize: 12, color: '#2da02d'}}>Slack connected successfully. Redo:  </span>}
          <a 
            onClick={() => analyticsTrack('admin_connectSlack', {existing_slack: this.props.self.company.slack_id, company_id: this.props.self.company.id})}
            href={"https://slack.com/oauth/authorize?client_id=486501047445.504636838914&scope=users:read,users:read.email,team:read,channels:read,groups:read&state=" + this.props.self.company.id}>
              <img 
                alt="Add to Slack" 
                height="40" 
                width="139" 
                src="https://platform.slack-edge.com/img/add_to_slack.png" 
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </a>
        </Section>

        <Section title="Admins">
          <div className="d-flex flex-row justify-content-between align-items-center">

          <PeopleSelector style={{width: '60%'}} label="Add Admin" searchKey={this.props.searchKey} onSelectPerson={(person) => this.setState({selectedPerson: person})} />
          <Button style={{width: '35%'}} onClick={addSelectedPerson} appearance="primary">Add Admin</Button>
          </div>
          <h5 style={{textDecoration: 'underline'}}>Current Admins</h5>
          {admins}
        </Section>

        <Section title="Reactivate User" subtitle="Activate previously deactivated users">
          <div className="d-flex flex-row justify-content-between align-items-center">

          <PeopleSelector active="false" style={{width: '60%'}} label="Activate User" searchKey={this.props.searchKey} onSelectPerson={(person) => this.setState({selectedActivate: person})} />
          <Button style={{width: '35%'}} onClick={() => this.state.selectedActivate ? activatePerson(this.state.selectedActivate) : null} appearance="primary">Activate User</Button>
          </div>
          <h5 style={{textDecoration: 'underline'}}>Deactivated Users</h5>
          {deactivated_users}
        </Section>

        <Section title="Reactivate Group" subtitle="Activate previously deactivated groups">
          <div className="d-flex flex-row justify-content-between align-items-center">
          <GroupSelector 
            active="false"
            style={{width: '60%'}}
            label="Activate Group" 
            searchKey={this.props.searchKey}               
            onSelectGroup={(group) => this.setState({selectedGroup: {id: parseInt(group.objectID)}})}
          />
          <Button style={{width: '35%'}} onClick={() => this.state.selectedGroup ? activateGroup(this.state.selectedGroup) : null} appearance="primary">Activate Group</Button>
          </div>
          <h5 style={{textDecoration: 'underline'}}>Deactivated Groups</h5>
          {deactivated_groups}
        </Section>

        <Section title="Standard Fields"
                subtitle="Enable or disable standard fields">
        {this.renderField("Github")}
        {this.renderField("Manager")}
        {this.renderField("Joined")}
        {this.renderField("Birthday")}
        {this.renderField("Daily_Digest")}
        {this.renderField("Email_All_Posts")}
        </Section> 

        <Section title="Custom Fields"
                 subtitle="Add custom fields (e.g. Pronouns, Shirt Size, Myers-Briggs Personality, etc.) Disabled fields won't show up on employee profiles">
          {this.renderCustomFieldsSection()}
        </Section>
        {this.renderSaveButton()}
      </div>
    );
  }

  /**
   * Renders the save button.
   */
  renderSaveButton() {
    return(
      <div className="full-width-item mx-auto">
        <Button
          onClick={() => this.onSubmit()}
          appearance="primary"
          className="ml-auto my-4 mr-3"
          style={{
            width: '80px'
          }}>
          {
            !this.state.loading &&
            'Save'
          }
          {
            this.state.loading &&
            <Loader type="ThreeDots" color="#fff" width={40} />
          }
        </Button>
      </div>
    );
  }

  render() {
    const { settings } = this.state;

    if (!settings) {
      return <PageLoader />;
    }

    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

export default withRouter(AdminSettingsTab);
