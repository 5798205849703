import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { analyticsTrack } from '../../../utils';

class TeamContact extends Component {
  render() {
    if (!this.props.team) {return null;}
    
    let point_of_contact = null;
    if (this.props.team.point_of_contact) {
      point_of_contact =
        <div className="point-of-contact-container d-flex justify-content-center">
          <span className="point-of-contact-label mt-1">Point of Contact</span>
          <div className="team-contact-person d-flex flex-row align-items-center justify-content-center">
          <div className="team-contact-person-info d-flex flex-column justify-content-center align-items-start pl-3 mt-1">
            <Link 
              onClick={() => analyticsTrack('group_pointOfContactClicked', {group: this.props.team.name, group_id: this.props.team.id, person: this.props.team.point_of_contact.email})}
              to={"/people/" + this.props.team.point_of_contact.email}>
              <h5 className="m-0 mb-1">{this.props.team.point_of_contact.first_name} {this.props.team.point_of_contact.last_name}</h5>
            </Link>
            <p>{this.props.team.point_of_contact.role}</p>
          </div>
          <img className="team-contact-person-image ml-auto mr-3"
               src={this.props.team.point_of_contact.photo}
               alt="profile" />
        </div>
      </div>;
    }

    const TeamContactInfo = props => {
      return(
        <div className="team-contact-info d-flex flex-row align-items-start mb-2">
          {props.children}
        </div>
      );
    };

    return (
        <div className="team-contact-container mx-lg-4 mt-4 mt-xl-0">
          <div className="team-contact-header d-flex justify-content-center align-items-center">
            Information
          </div>
          <div className="team-contact-content d-flex align-items-center justify-content-center flex-column pt-1 pb-2">
            <TeamContactInfo>
              <span className="justify-content-start mt-3 pl-4">Email</span>
              <a className="ml-auto mr-3 mt-2 mb-2"
                onClick={() => analyticsTrack('group_emailClicked', {group: this.props.team.name, group_id: this.props.team.id, email: this.props.team.contact_email})}
                 href={"mailto:" + this.props.team.contact_email}>
                {this.props.team.contact_email}
              </a>
            </TeamContactInfo>
            <TeamContactInfo>
              <span className="mt-3 pl-4">Slack</span>
              {this.props.team.contact_slack && <a className="ml-auto mr-3 mt-2 mb-2"
                onClick={() => analyticsTrack('group_slackClicked', {group: this.props.team.name, group_id: this.props.team.id, company_slack: this.props.team.company.slack_id, slack: this.props.team.contact_slack})}
                 href={"slack://channel?team=" + this.props.team.company.slack_id + "&id=" + this.props.team.contact_slack} >
                {this.props.team.contact_slack}
              </a>}
            </TeamContactInfo>
            
          </div>
          {point_of_contact}
        </div>
    );
  }
}

export default TeamContact;