import React, { Component } from 'react';
import ApiClient from '../../ApiClient.js';
import { withRouter } from 'react-router-dom'
import './styles/team.scss';

// shared components
import PageLoader from '../shared/components/PageLoader.react.js';
import PeopleGroupView from '../shared/components/PeopleGroupView.react.js';

// own components
import TeamContact from './components/TeamContact.react.js';
import TeamInfo from './components/TeamInfo.react.js';
import TeamResources from './components/TeamResources.react.js';
import { analyticsPage } from '../../utils.js';

class TeamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
    };
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    this.refreshData();
    analyticsPage("team_view");
  }

  refreshData() {
    ApiClient.get("/groups/" + this.props.team_id).then(
      data => { this.setState({ team: data }); }
    );
    if (this.refs.team_resources) {
      this.refs.team_resources.refreshData();
    }
  }

  /**
   * Navigates to the team edit page.
   */
  navigateToTeamEditPage() {
    this.props.history.push(`/team/${this.props.team_id}/edit`);
  }

  render() {
    if (!this.state.team) return (
      <PageLoader />
    );

    return (
      <div>
        <div className="team-top-container pb-5">
          <div className="top-container d-flex justify-content-center align-items-center flex-column flex-xl-row mx-auto pb-5 px-3 p-xl-4">
            <div className="team-top-second-container d-flex flex-column align-items-center justify-content-center flex-md-row">
              <TeamInfo openEdit={() => this.navigateToTeamEditPage()}
                        team={this.state.team}
                        self={this.props.self}
                        onJoinLeave={() => this.refreshData()} />
              <TeamResources ref="team_resources"
                             team_id={this.props.team_id}
                             team={this.state.team}
                             self={this.props.self}
                             openEdit={() => this.navigateToTeamEditPage()} />
            </div>
            <TeamContact team={this.state.team} />
          </div>
        </div>

        <div className="background-bottom">
          <PeopleGroupView path={"/groups/" + this.props.team_id + "/people"} header="Members" cta="Send a Message" />
        </div>
      </div>
    );
  }
}

export default withRouter(TeamPage);
