import React, { Component } from 'react';
import Container from './Container.react';

class Section extends Component {
  render() {
    const { title, subtitle } = this.props;
    return (
      <div className="light-gray-border-color py-4"
           style={{
             borderTopWidth: '1px',
             borderTopStyle: 'solid',
             width: '100%',
           }}>
        <div className="background-bottom mx-auto">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="text-left">
                  <h6>{title}</h6>
                  <p className="light-gray-text"
                     style={{
                      maxWidth: 300,
                      fontSize: 14,
                    }}>{subtitle}</p>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <Container>
                  {this.props.children}
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section;
