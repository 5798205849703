import React, { Component } from 'react';
import '../styles/new_hires.scss';
import { Link } from 'react-router-dom';
import { analyticsTrack } from '../../../utils.js';

class PeopleSmallCard extends Component {
  render() {
    const person = this.props.person;

    return (
      <Link 
        onClick={() => analyticsTrack('peopleCard_personClick', {path: window.location.pathname, person: person.email})}
        className="new-hire-link router-link rl-black" 
        to={"/people/" + person.email}>
        <div key={"ppl_" + person.email} className="new-hire-container grow p-2 mb-3 text-left" >
          <div className="row" style={{ maxWidth: 400, }}>
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
              <div className="new-hire-text-container">
                    <h5>{person.first_name} {person.last_name}</h5>
                </div>
                <div className="new-hire-picture-container d-flex align-items-center ml-auto" >
                  <img src={person.photo} alt="profile" style={{marginLeft: 15, height: 30, borderRadius: 30}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default PeopleSmallCard;