import React, { Component } from 'react';

class ItemContainer extends Component {
  render() {
    const { name, children } = this.props;

    return (
      <div className="d-flex flex-row mb-3 mb-sm-0 align-items-center text-left justify-content-between">
        <span className="d-block d-sm-none mr-2">
          {name}:
        </span>
        {children}
      </div>
    );
  };
}

export default ItemContainer
