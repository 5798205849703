import React, { Component } from 'react';

class Title extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className="black-text" style={{
        fontSize: 16,
      }}>
        {children}
      </div>
    );
  }
}

export default Title;
