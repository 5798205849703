import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TextInputField, toaster } from 'evergreen-ui';
import './resources.scss';

// shared components
import ResourcesRow from './ResourcesRow.react';
import PageLoader from '../PageLoader.react';
import Button from '../Button.react';
import ItemContainer from '../ItemContainer.react';

// utils
import { isValidLink } from '../../utils';

class EditResources extends Component {
  /**
   * Error messages.
   */
  errors = {
    tooManyResources: 'There are too many resources',
  };

  /**
   * Validation values.
   */
  validations = {
    maxCount: {
      resource: 4,
    },
    minTitle: 1,
  };

  constructor(props) {
    super(props);

    const { minTitleLength, maxResourceCount } = this.props;
    // overwrite the validations if specified
    if (minTitleLength) {
      this.validations.minTitleLength = minTitleLength
    }
    if (maxResourceCount) {
      this.validations.maxCount.resource = maxResourceCount;
    }
  }

  /**
   * Renders a resource.
   *
   * @param title
   * @param link
   * @param resourceIndex
   * @returns {*}
   */
  renderResource({ title, link }, resourceIndex) {
    return (
      <ResourcesRow key={`Resource${resourceIndex}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-6">
              <ItemContainer name="Title">
                <TextInputField value={title}
                                isInvalid={this.props.submitClicked && (title.length < this.validations.minTitle)}
                                placeholder="Resource title"
                                label="Resource title"
                                margin={0}
                                width="100%"
                                onChange={e => this.onResourceTitleChanged(e.target.value, resourceIndex) } />
              </ItemContainer>
            </div>

            <div className="col-12 col-sm-4">
              <ItemContainer name="Link">
                <TextInputField isInvalid={this.props.submitClicked && !isValidLink(link)}
                                margin={0}
                                label="Resource link"
                                value={link}
                                width="100%"
                                onChange={event => this.onLinkChanged(event.target.value, resourceIndex)} />
              </ItemContainer>
            </div>
            <div className="col-12 col-sm-2">

              <ItemContainer name="Actions">
                {
                  this.props.resources.length > 1 &&
                  <span onClick={() => this.onDeleteResourceClicked(resourceIndex)}
                      className="red-text clickable mt-1 ml-2">Delete</span>
                }
              </ItemContainer>
            </div>
          </div>
        </div>
      </ResourcesRow>
    );
  }

  /**
   * Renders resources;
   *
   * @param resources
   * @returns {*}
   */
  renderResources( resources ) {

    return (
      <div 
           className="background-bottom white-background mx-auto"
           style={{
             borderRadius: '4px',
           }}>
        <div>
          <div className="d-flex flex-row justify-content-between blue-background white-text py-3 mt-3 "
               style={{
                 borderRadius: '4px 4px 0 0',
               }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-5 category-title-input">
                  <h5>Resources</h5>
                </div>

                <div className={"col-0 col-sm-1 col-md-" + (this.props.smaller ? '2' : '5')}></div>

                <div className={"col-12 col-sm-6 text-right col-md-" + (this.props.smaller ? '5' : '2')}>
                  <div className="d-flex flex-column flex-sm-row mt-3 mt-sm-0">
                    <Button
                      style={{
                        width: '100%'
                      }}
                      className="px-3"
                      onClick={() => this.onAddResourceClicked()}
                      appearance="dark">Add Resource</Button>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-sm-block light-gray-text">
            <ResourcesRow>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <span className="resources-header">Resource Name</span>
                  </div>
                  <div className="col-sm-6">
                    <span className="resources-header">Link</span>
                  </div>
                </div>
              </div>
            </ResourcesRow>
          </div>
          {
            resources.map((resource, resourceIndex) => this.renderResource(resource, resourceIndex))
          }
        </div>
      </div>
    );
  }

  /**
   * Adds an empty resource given the total amount has not reached maximum.
   *
   */
  onAddResourceClicked() {
    const { resources } = this.props;

    if (resources.length >= this.validations.maxCount.resource) {
      return toaster.danger(this.errors.tooManyResources);
    }

    resources.push({ title: '', link: '' });

    this.props.updateResources(resources);
  }

  /**
   * Resource title changed event handler.
   *
   * @param {string} value
   * @param {int} resourceIndex
   */
  onResourceTitleChanged(value, resourceIndex) {
    const { resources } = this.props;

    resources[resourceIndex].title = value;

    this.props.updateResources(resources);
  }

  /**
   * Deletes a resource.
   *
   * @param {int} resourceIndex
   */
  onDeleteResourceClicked(resourceIndex) {
    const { resources } = this.props;

    resources.splice(resourceIndex, 1);

    this.props.updateResources(resources);
  }

  /**
   * resource link changed event handler.
   *
   * Parses the value to be a number because in a select it is a string by default.
   *
   * @param {string} value
   * @param {int} resourceIndex
   */
  onLinkChanged(value, resourceIndex) {
    const { resources } = this.props;

    resources[resourceIndex].link = value;

    this.props.updateResources(resources);
  }

  /**
   * Renders the bottom page content with all sections.
   */
  renderBottomContent() {
    const { resources } = this.props;

    return (
      <div className="text-left">
        {
          this.renderResources(resources)
        }
      </div>
    );
  }

  /**
   * Component renderer.
   *
   * @returns {*}
   */
  render() {
    if (!this.props.resources) {
      return <PageLoader />
    }

    return (
      <div className="light-gray-border-color py-4"
           style={{
             borderTopWidth: '1px',
             borderTopStyle: 'solid',
             width: '100%',
           }}>
        <div className="background-bottom mx-auto">
          <div className="container-fluid">
            <div className="row">
              {this.props.smaller &&
                <div className="col-6">
                </div>
              }
              <div className={"col-12 " + (this.props.smaller ? 'col-lg-6' : '')}>
                {this.renderBottomContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditResources);
