import React, { Component } from 'react';
import '../styles/search.scss';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
    }
  }

  componentDidMount() {
    if (this.props.focus) {
      this.refs.search_input.focus();
    }
  }

  render() {
    return (
      <div className={this.props.class_prefix + "-search-container"}>
        {this.props.icon_position !== 'right' && 
          <i className="fas fa-search search-icon" />
        }
        <input className={this.props.class_prefix + "-search"}
               placeholder={this.props.placeholder || "Search"}
               aria-label="Search"
               type="text"
               ref="search_input"
               value={this.state.searchQuery}
               onChange={(event) => this.setState({searchQuery: event.target.value})}
               onKeyUp={(e) => {
                 const keyCode = e.keyCode || e.which;
                 if (keyCode === 13) {
                   this.props.search(this.state.searchQuery);
                   this.setState({searchQuery: ''});
                   this.refs.search_input.blur();
                 }
              }}
            />
        {this.props.icon_position === 'right' && 
          <i className="fas fa-search search-icon" />
        }
      </div>
    );
  }
}

export default SearchBar;
