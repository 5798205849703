import React, { Component } from 'react';
import ApiClient from '../../ApiClient.js';
import { withRouter } from 'react-router-dom'
import './styles/teams.scss';
import { Link } from 'react-router-dom';

import Button from '../shared/components/Button.react';
import PageTopContent from '../shared/components/PageTopContent.react';
import PageLoader from '../shared/components/PageLoader.react';
import { analyticsPage, analyticsTrack } from '../../utils.js';

class TeamsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: null,
    };
  }

  componentDidMount() {
    ApiClient.get(this.props.path).then(
      data => { console.log(data); this.setState({ teams: data }); }
    );
    analyticsPage("teams_list");
  }

  /**
   * Navigates to create team/group page.
   */
  onCreateClicked() {
    const link = this.props.isTeam ? '/create-team' : '/create-group';

    this.props.history.push(link);
  }

  /**
   * Renders the create button.
   * @returns {*}
   */
  renderCreateButton() {
    if (this.props.isTeam && this.props.isCompanyAdmin !== true && this.props.disabledFields.indexOf('team') > -1) {
      return;
    }

    const type = this.props.isTeam ? 'Team' : 'Group';

    return (
      <Button
        className="px-3 ml-auto"
        style={{
          maxWidth: '150px'
        }}
        onClick={() => this.onCreateClicked()}
        appearance="dark">Create {type}</Button>
    );
  }

  render() {
    if (!this.state.teams) return (
      <PageLoader />
    );

    const teams = this.state.teams.map((team) =>
      <Link key={team.id}
            to={"/team/" +  team.id}
            onClick={() => analyticsTrack('teams_teamClicked', {'group': team.name, 'is_team': this.props.isTeam, 'group_id': team.id})}
            className="mb-1"
            style={{ textDecoration: 'none' }}>
        <div className="team-container grow d-flex flex-row mb-4 mb-xl-5 mx-auto">
          <div className="team-text-container d-flex flex-column justify-content-center text-left mr-1 mt-3 ml-3 ml-xl-4">
            <div className="mb-1">
              <h5>{team.name}</h5>
            </div>
            <p>{team.description}</p>
          </div>
          <div className="d-flex flex-row align-items-center ml-auto mr-3 mr-xl-4">
            <div className="team-count-circle d-flex align-items-center justify-content-center"
                 style={{zIndex: team.random_pictures.length + 1}}>{team.num_members}</div>
            {team.random_pictures.map((picture, index, arr) =>
              <img key={"pic" + index}
                   alt="profile"
                   className="team-count-circle team-picture-circle"
                   style={{zIndex: arr.length - index}}
                   src={picture}></img>
            )}
          </div>
        </div>
      </Link>
    );

    const CHUNK_SIZE = 2;
    const chunked = [];
    for (let i = 0; i < teams.length; i += CHUNK_SIZE) {
      let chunk = teams.slice(i, i + CHUNK_SIZE);
      chunked.push(chunk);
    }

    const teamRows = chunked.map((components, index) =>
      <div key={"row_" + index} className="col-12 col-lg-6">
        {components}
      </div>
    );
    const title = this.props.isTeam ? 'Teams' : 'Groups';

    return (
      <div>
        <PageTopContent title={title} >
        <img src={this.props.headerImg} className="header-image" alt="mascot"/>

          {this.renderCreateButton()}
        </PageTopContent>

        <div className="d-flex align-items-center mt-3 mt-md-4">
          <div className="background-bottom">
            <div className="container-fluid">
              <div className="row">
                {teamRows}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TeamsPage);
